.calendar {
    .fc-th-td {
      color: $dark-text;
    }
  h2 {
    color: $dark-text;
  }
  .fc-view {
    overflow:visible;
  }
}
