.workflow-step {

  .workflow-select {
    margin-bottom: 10px;
  }

  form{
    padding-top: 0;
  }

  .hspc-styled-select {
    margin: rem-calc(10) 0;
  }

  .bottom-padding{
    padding-bottom: rem-calc(10);
  }

  .confirm-table {

    @include breakpoint(tablet down) {
      th{
        display: table-cell;
      }
    }

    tr {
      td,
      th {
        &:first-child {
          @include breakpoint(tablet){
            width: 450px;
            max-width: 450px;
          }

          @include breakpoint(phablet){
            width: 350px;
            max-width: 350px;
          }

          @include breakpoint(phone){
            width: 300px;
            max-width: 300px;
          }
        }
      }
    }
  }

  .test-description,
  .isc-form-group-stacked {
    margin-top : rem-calc(20);
  }

  .no-results-box{
    margin: rem-calc(10) 0;
    padding: rem-calc(15);
    background: $secondary-color;
    border: 1px solid $app-border-color;
  }
}