/* Footer Settings */
$footer-background:  $primary-color !default;

/* Footer Styles */
@include exports(footer) {
  .isc-footer {
    background: $footer-background;
    margin: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: $horizontal-container-padding;
    padding-right: $horizontal-container-padding;
    width: 100%;

    @media #{$medium-down} {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: $horizontal-container-padding-small;
      padding-right: $horizontal-container-padding-small;
    }

    .menu-bar {
      background: transparent;

      li {
        color: $app-bar-link-color;
        line-height: 1;
        margin: 0;

        &:first-of-type {
          a, p {
            padding-left: 0;
          }
        }

        &:last-of-type {
          a, p {
            padding-right: 0;
          }
        }

        a, p {
          color: $app-bar-link-color;
          margin: 0;
          font-weight: $font-weight-normal;
          font-size: 0.75rem;
          padding: 0.5rem 1rem;
          line-height: 1;

          @media #{$small} {
            padding: 0.5rem 0.5rem;
          }
        }

        a {
          &:hover, &:focus, &:active {
            color: $app-bar-link-hover-color;
            background: transparent;
          }
        }
      }
    }

    .grid-container {
      padding: 0;
    }
  }

}
