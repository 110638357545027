.hspc-file-upload {

  margin-top : rem-calc(20);

  .button {
    margin : 0;
  }

  .hidden-file {
    width    : 0.1px;
    height   : 0.1px;
    opacity  : 0;
    overflow : hidden;
    position : absolute;
    z-index  : -1;
  }

  .label {
    width: 25%;
  }

  .attachments {
    margin-top : rem-calc(20);
  }
}