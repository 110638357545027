/* Color Theming
  ----------------------------------------------
 - Extends all Foundation Components to work with framework theme colors & theming utility classes
 - Components should use smart calculations whenever possible with fallbacks passed in as defaults
 - WIP - Lots more to do here, just getting started
---------------------------------------------- */


/* Themable Utility Classes for Framework Components */

/* Color Style Exports */
@include exports(colors) {
  // Notifications
  .notification, .static-notification {

    h1, h2, h3, h4, h5, h6 {
      color: isitlight($notification-background, $header-font-color, $light-text);
      margin-bottom: $global-padding/2;
    }

    p, label {color: isitlight($notification-background, $body-font-color, $light-text);}

    .close-button {color: isitlight($notification-background, $body-font-color, $light-text);}

    @each $color in map-keys($theme-colors) {

    &.#{$color} {
      $color-name: map-get($theme-colors, $color);
      background: $color-name;
      color: isitlight($color-name, $body-font-color, $light-text);

        h1, h2, h3, h4, h5, h6 {color: isitlight($color-name, $header-font-color, $light-text); margin-bottom: $global-padding/2;}
        p, label {color: isitlight($color-name, $body-font-color, $light-text);}
        .close-button {color: isitlight($color-name, $body-font-color, $light-text);}
      }
    }
  }
}
