// HSPC-2856 - fix for IE11 display issue with field groups
.formly-field-group {
  width: 100%;
}

.wizard-cancel-btn {
  flex-grow : 10;
}

.wizard-timeline {
  display       : flex;

  margin-top    : 2rem;
  margin-bottom : 2rem;

  .circle {
    background-color : $primary-color;
    background-size  : 2.5rem 2.5rem;
    border-radius    : 50%;
    width            : 2.5rem;
    height           : 2.5rem;
    position         : relative;
    margin-top       : 0;
    color            : white;
    text-align       : center;
    font-size        : 1.5rem;
    padding          : 0.375rem;
    line-height      : 1;
    vertical-align   : middle;
    margin-right     : 1rem;
    border           : 2px solid $primary-color;
    z-index          : 1;
  }

  .circle.hollow {
    background-color : white;
    color            : $primary-color;
  }

  .line {
    padding-top : 0;

    &:before {
      content    : "";
      position   : absolute;
      height     : 2px;
      width      : 100%;
      background : $primary-color;
      top        : 50%;
      left       : 0px;
      z-index    : 0;
    }
  }

  button {
    background-color : transparent;
    border           : none;
    margin           : 0;
    padding          : 0;
  }
}
input:valid, input.ng-pristine, select, textarea {
  border-color: lighten( $gray-vdark, 12% );
}
