.content {
  margin : $hspc-gutter 0;
  #fullSchedulePage {
    padding : 25px;
    @media only screen and (max-width : 400px) {
      #fullScheduleHeader {
        font-size : 25px;
      }
    }

    .fc-event {
      border-color     : darken($neutral-color, 40%);
      background-color : $neutral-color !important;
      margin-top       : 5px;
    }
    .fc-content {
      height           : 35px;
      background-color : #fff;
      color            : $primary-color;
      padding          : 3px;
      line-height      : 34px;
      max-width        : 500px;
      span {
        font-size   : 18px;
        @media only screen and (max-width : 775px) {
          font-size : 16px;
        }
        font-weight : 500;
      }
      &:hover {
        background-color : $primary-color;
        color            : #fff;
      }
    }

    #calendar {
      .fc-basicDay-view {
        margin    : 0 auto;
        max-width : 500px;
      }

      .fc-basicWeek-view {
        @media only screen and (max-width : 600px) {
          .fc-content {
            line-height : 24px;
            padding     : 1px;
            height      : 24px;
            span {
              font-size   : 12px;
              font-weight : 700;
            }
          }
        }
      }
    }
    .typeSelection {
      margin : 0 auto;
      select {
        width     : 30%;
        min-width : 200px;
      }
    }
  }

  #inQuickerLogo {
    margin-top : 20px;
    text-align : right;
  }
  body {
    padding : 100px;
  }
  .tabs {
    height   : 45px;
    padding  : 0 0 0 0;
    overflow : visible;
  }
  .tab {
    width    : 225px;
    height   : 45px;
    overflow : hidden;
    float    : left;
    margin   : 0 -15px 0 0;

    &:not(.active) {
      cursor : pointer;
    }

    @media only screen and (max-width : 530px) {
      &.active {
        display : none;
      }
    }

  }

  .tab-box {
    height        : 50px;
    background    : #fff;
    border-radius : 4px;
    border        : 1px solid #ccc;
    margin        : 0 10px 0;
    box-shadow    : 0 0 2px #fff inset;

    h3 {
      font-size   : 18px;
      margin-left : 15px;
      margin-top  : 15px;
    }

  }

  .tab.active {
    z-index        : 40;
    position       : relative;
    padding-bottom : 1px;
  }
  .tab.active .tab-box {
    background-color : darken($neutral-color, 10%);
    box-shadow       : 0 0 2px 0 #fff inset;
  }

  .tab-content {
    z-index  : 1;
    border   : 1px solid darken($neutral-color, 10%);
    position : relative;

    .inline-label {
      max-width : 300px;
    }

    .startPage {

      .navigationDiv {
        text-align : center;
        margin-top : 20px;
        button {
          padding       : 10px;
          border-radius : 20px;
        }
        #navigationText {
          font-weight : 300;
          color       : $primary-color;
          font-size   : 30px;
        }
      }

      .searchBox, .filter-container {
        label {
          max-width : 280px;
          input {
            padding : 5px 0 5px 7px;
          }
          select {
            margin-top : 10px;
          }
        }
        background-color : darken($neutral-color, 10%);
        padding          : 25px;
        border-bottom    : 3px solid darken($neutral-color, 15%);
      }

      .noResultsDiv {
        background-color : whitesmoke;
        padding          : 20px;
      }
    }

  }

  .clear {
    clear : both;
  }

  h3 {
    margin-bottom : $hspc-gutter;
  }

  .search-buttons {
    padding : $hspc-gutter 0;
  }

  .filter-container {

    #radiusPicker {
      width      : 200px;
      margin-top : 20px;
      text-align : center;
    }

    p {
      display : inline-block;
    }

    .switch.small {
      margin-left    : 10px;
      margin-bottom  : 3px;
      vertical-align : middle;
    }

    button {
      margin-top : 20px;
    }
  }

  .providerHeader {
    background-color : white;
    padding          : 25px;
    border-bottom    : 3px solid darken($neutral-color, 15%);

    h1 {
      @media only screen and (max-width : 550px) {
        font-size : 1.5rem;
      }
    }
    img {
      width        : 15%;
      min-width    : 95px;
      display      : inline-block;
      margin-right : 20px;
    }
    .providerDetails {
      margin : 15px 0 20px 0;
      .detailsContent {
        width          : 80%;
        display        : inline-block;
        vertical-align : top;
        p {
          font-size : 18px;
        }

      }
    }
  }

  .providerDiv {
    padding          : 15px;
    border-bottom    : 2px solid darken($neutral-color, 10%);
    cursor           : pointer;
    background-color : $neutral-color;
    img {
      display : inline-block;
    }
    .detailsContent {
      display        : inline-block;
      vertical-align : top;
      margin-left    : 10px;

      @media only screen and (max-width : 500px) {
        display     : block;
        margin-left : 0;
      }
    }
  }

  .providerDiv:hover {
    background-color : white;
  }

  .resultsNumDiv {
    background-color : $neutral-color;
    &.padding1 {
      padding : 30px 0 0 30px;
    }
    &.padding2 {
      padding : 30px 0 20px 15px;
    }
    h2 {
      color         : darken($gray-vdark, 10%);
      margin-bottom : 0px;
    }
  }

  .schedules {
    padding          : 30px;
    background-color : $neutral-color;
    border-bottom    : 2px solid darken($neutral-color, 10%);
    .infoDiv {
      display : inline-block;
      div .darkGrey {
        color        : darken($gray-vdark, 10%);
        padding      : 5px 0 5px 15px;
      }
      h1 {
        @media only screen and (max-width : 550px) {
          font-size     : 1.5rem;
          margin-bottom : 20px;
        }
      }

      h2 {
        @media only screen and (max-width : 550px) {
          font-size : 18px;
        }
        margin-bottom : 10px;
      }
      div {
        i {
          margin-right : 3px;
          &.fa-map-marker {
            float        : left;
            margin-right : 6px;
          }
          &.fa-circle {
            margin-left    : 3px;
            vertical-align : middle;
            font-size      : 3px;
          }
        }
        .name {
          display : inline-block;
          @media only screen and (max-width : 550px) {
            width : 150px;
          }
        }
        .grey {
          margin : 5px 0 10px 15px;
          color  : darken($neutral-color, 55%);
        }
      }
    }
    .typeSelectionDiv {
      float         : right;
      margin-bottom : -20px;
      min-width     : 200px;
      max-width     : 275px;
      @media only screen and (max-width : 550px) {
        float        : left;
        margin-right : 25%;
      }
      label {
        margin-top : 10px;
        select {
          width         : 100%;
          margin-top    : 10px;
          margin-bottom : 2.5rem;
        }
      }
    }
  }

  .outerContainer {
    overflow-y : auto;
    max-width  : 840px;
    margin     : 0 auto;
    text-align : center;
  }

  .appointmentBlockContainer {
    margin-top   : 20px;
    margin-right : 40px;
    div {
      a {
        padding-top : 20px;
      }
    }
    .container {
      display : inline-block;
    }

    &.fullSchedule {
      max-width        : 200px;
      background-color : $neutral-color;
      padding          : 15px;
      display          : inline-block;
      vertical-align   : top;
      margin           : 0 25px 0 25px;
      @media only screen and (max-width : 1190px) {
        display   : block;
        max-width : 100%;
        padding   : 15px 0 15px 0;
        margin    : 25px 0 25px 0;
        div {
          .appointmentBlock {
            display : inline-block;
            width   : 85px;
            margin  : 5px;
            height  : 40px;
            h2 {
              padding     : 5px;
              font-size   : 16px;
              line-height : 30px;
            }
          }
        }
      }
    }
    span {
      font-size     : 15px;
      color         : darken($gray-vdark, 10%);
      font-weight   : bold;
      display       : block;
      margin-bottom : 10px;
    }

    .appointmentBlock {
      cursor         : pointer;
      vertical-align : middle;
      border         : 2px solid darken($neutral-color, 10%);
      width          : 150px;
      text-align     : center;
      max-height     : 100px;
      margin         : 10px auto 20px auto;
      h2 {
        padding     : 0 10px 10px 10px;
        margin-top  : 0;
        line-height : 1rem;
        display     : block;
        &.padded {
          padding : 15px;
        }
      }
      &:hover {
        background-color : $primary-color;
        h2 {
          color : white;
        }
      }
    }
  }
  .noTimesDiv {
    display       : inline-block;
    padding       : 10px;
    width         : 60%;
    min-width     : 150px;
    margin-bottom : 20px;

    p {
      font-weight : 600;
      color       : darken($neutral-two, 50%);
    }

    &.fullSchedule {
      font-size : 20px;
      margin    : 0 auto;
    }
  }
}

.message-title-prefix {
  padding-right : rem-calc(10);
}

.confirm-table {
  .button.secondary {
    margin-bottom : 0;
  }

  th, td {
    white-space : normal;
  }
}

form {
  .grid-content {
    padding-left : 0;
  }
}

.workfow-buttons {
  border : 1px solid $app-border-color;

  .section {
    padding       : 1rem;
    align-content : center;
    flex-wrap     : nowrap;

    &.top {
      .button {
        &:first-child {
          margin-#{$global-right} : 1rem;
          margin-#{$global-left}  : 0;
          @include bidirectional {
            margin-right : 0;
            margin-left  : 1rem;
          }
        }
      }
    }

    &.bottom {
      justify-content : flex-end;
      @include breakpoint(tablet down) {
        .button {
          margin : 0;
          &:first-child {
            margin-bottom : 1rem;
          }
        }
      }
    }
  }

  .button {
    margin-bottom : 0;

    @include breakpoint(tablet) {
      &:first-child {
        margin-right : 1rem;
      }
    }

    @include breakpoint (tablet down) {
      width : 100%;
      &:last-child {
        margin-right : 0;
      }
    }
  }
}

.margin-top {
  margin-top : 15px;
}

.inQuickerConfirmPage {
  padding : 20px;
  .inQuickerForm {

    #buttonDiv {
      padding : 15px;
      border  : 1.5px solid $neutral-color;
      button, input {
        margin : 0;
      }
    }

    label::before {
      content : '';
    }
    .salmon {
      color : $alert-color;
    }

    .conditions {
      margin-top       : 20px;
      margin-bottom    : 20px;
      padding          : 25px;
      font-size        : 9px;
      background-color : darken($neutral-color, 10%);
      color            : darken($neutral-color, 20%);
    }
    h2 {
      font-size     : 20px;
      margin-bottom : 10px;
    }
    h3 {
      color         : darken($gray-dark, 40%);
      font-weight   : 600;
      margin-top    : 20px;
      margin-bottom : 5px;
    }
    h3:first-child {
      margin-top : 0px;
    }
    .padded {
      margin-bottom : 20px;
      margin-top    : 20px;
    }
    .inQuickerConfirm {
      background-color : $neutral-color;
      padding          : 25px;
      display          : inline-block;
      width            : 100%;
      min-width        : 300px;
      margin-bottom    : 30px;
      #typeDiv {
        margin-top : 15px;
      }
      textarea {
        width : 80%;
      }
      label {
        display      : inline-block;
        margin-right : 20px;
        @media only screen and (max-width : 600px) {
          max-width : 200px;
        }
      }

      .textAreaLabel {
        display   : block;
        max-width : 500px;
      }
      .inlineDiv {
        margin : 10px 0 10px 0;
        select {
          margin-top    : 7px;
          margin-bottom : 0px;
        }
        .emailInput {
          vertical-align : top;
        }
        label {
          min-width    : 5em;
          margin-right : 100px;
          &.shiftLeft {
            margin-right : 90px;
          }
          &.shiftLeft2 {
            margin-right : 30px;
          }
          input:not([type='radio']) {
            display : block;
            width   : 100%;
          }
        }
        div {
          display : inline-block;
        }
        .hspc-form-error-message {
          width : 11.5em;
        }
      }
      .shrink {
        width : 7.5em;
        input {
          width : 6em;
        }
      }
      .shrink2 {
        vertical-align : top;
        width          : 13em;
        input {
          width : 11.5em;
        }
      }
      .large {
        width : 24.5em;
        input {
          width : 100%;
        }
      }
    }
  }
}

//end workflow-buttons
