body .buorg {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid #A29330;
  background: $warning-color;
  text-align: center;
  cursor: pointer;
  font-family: 'Open Sans';
  color: #000;
  font-size: 1.5rem;
}

body .buorg div {
  padding: 5px 36px 5px 40px;
}

body .buorg a {
  color: #E25600;
}

body #buorgclose {
  position: absolute;
  right: .5em;
  top: .2em;
  height: 20px;
  width: 12px;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
}