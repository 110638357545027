.hspc-main-mobile-menu {

  @include breakpoint (tablet up){
    display:none;
  }

  background-color : $secondary-color;
  overflow         : hidden;
  @include breakpoint (tablet down){
    width: 50%;
  }
  @include breakpoint(phablet down){
    width: 100%;
    }
  .menu-wrapper {
    height : 100vh;
  }

  .brand-nav {
    justify-content: space-between;

    align-items: center;

    .logo {
      @include breakpoint(tablet down) {
        background-image  : $logo;
        background-position-y: 5px;
        background-repeat : no-repeat;
        background-size   : rem-calc(120px);
        order: 1;
      }
    }

    .language {
      order: 2;
      width: 160px;
      align-items: center;

      select {
        margin: 0;
      }

      @include ltr{
        justify-content: flex-start;
      }

      @include bidirectional{
        justify-content: flex-end;
      }

      &.hspc-styled-select {
        align-self : center;
      }
    }

    .close-button-2 {
      order: 3;
      font-size:44px;
      color       : darken(white, 5);
      font-weight : bold;

      &:hover {
        color : darken(white, 15);
      }

      &.secondary {
        position : relative;
        top      : unset;
        right    : unset;
      }
    }
  }


  @import "../../assets/sass/hspc/hspc-tabs";

  .footer {
    border-top       : 1px solid $app-border-color;
    background-color : $gray-vdark;
    color            : white;

    padding          : rem-calc(15);

    .component-label {
      padding-right : 10px;
    }

    .user {
      align-self : center;
    }

    .action {
      .button {
        margin-bottom : 0;
      }
    }

    .top {
      margin-bottom : rem-calc(10);
    }

    .bottom {
      a {
        color           : white;
        text-decoration : none;
        font-size       : rem-calc(12);

        &:hover {
          color : $brand-link;
        }
      }
    }
  }
}

.hspc-timeline-mobile-menu{
  @include breakpoint (tablet up){
    display:none;
  }
}
