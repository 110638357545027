// --------------------------------------------------

// media queries

// --------------------------------------------------



$phone-min: rem-calc(0);

$phone-max: rem-calc(639);



$phablet-min: rem-calc(640);

$phablet-max: rem-calc(959);



$tablet-min: rem-calc(960);

$tablet-max: rem-calc(1199);



$desktop-min: rem-calc(1200);

$desktop-max: rem-calc(1439);



$phone-only:  "all and (min-width: 0) and (max-width: #{$phone-max})";// @include breakpoint( phone only)

$phablet-only:  "all and (min-width: #{$phablet-min}) and (max-width: #{$phablet-max})";

$tablet-only:  "all and (min-width: #{$tablet-min}) and (max-width: #{$tablet-max})";

$desktop-only:  "all and (min-width: #{$desktop-min}) and (max-width: #{$desktop-max})";



$phone-up: "all and (min-width: 0)"; // @include breakpoint( phone )

$phablet-up: "all and (min-width: #{$phablet-min})";

$tablet: "all and (min-width: #{$tablet-min})";

$desktop-up: "all and (min-width: #{$desktop-min})";



$phablet-down: "all and (max-width: #{$phone-max})"; // @include breakpoint( phablet down) or @include breakpoint( phone only)

$tablet-down: "all and (max-width: #{$phablet-max})";

$desktop-down:  "all and (max-width: #{$tablet-max})";



// tablet and desktop

$desk-tab: "all and (min-width: #{$tablet-min}) and (max-width: #{$desktop-max})";



// phablet and tablet

$tab-phab: "all and (min-width: #{$phablet-min}) and (max-width: #{$tablet-max})";



// phone and phablet

$phab-phone: "all and (min-width: 0) and (max-width: #{$phablet-max})";



// --------------------------------------------------

// colors

// --------------------------------------------------



//--------------------------------------------

// SOLID DARK

//--------------------------------------------

$secondary-success: green;

$secondary-success-color: #1aaf54;

$logo-color: white;



$brand-page-title-color: $primary-color;

$brand-page-subtitle-color: $gray-dark;



$nav-link: #628080 !default;

$nav-text: #fdfbff !default;

$button-primary:        $primary-color !default;

$button-primary-disabled: #bfddd7 !default;



$app-border-color: darken( $secondary-color, 10 );

$panel-border: 1px solid $app-border-color;

$font-disabled: $gray-light;



$tr-bkgrnd-light: $secondary-color;

$tr-bkgrnd-dark: white;



$top-nav-background: $primary-color ;

$footer-background: $primary-color;

$side-nav-background: $primary-color;



// --------------------------------------------

// THEME SKY

// --------------------------------------------

//$secondary-success: green;

//$secondary-success-color: #1aaf54;

//$brand-link: #a55000 !default;

//$logo-color: white;

//

//$nav-link:                   #5c7b7d !default;

//$nav-text:                   #000400 !default;

//$button-primary:             $primary-color !default;

//$button-primary-disabled:    #bfddd7 !default;

//

//$brand-page-title-color: $primary-color;

//$brand-page-subtitle-color: $gray-dark;

//

//$app-border-color: #dfe0db;

//$panel-border: 1px solid $app-border-color;

//$app-border-drop-shadow: 0px 3px 8px 0px rgba(0,0,0,0.65);

//

//$tr-bkgrnd-dark: lighten( $secondary-color, 5 );

//$tr-bkgrnd-light: white;

//

//$top-nav-background: $primary-color url( "../assets/images/blue-clouds.jpg" );

//$footer-background: $primary-color url( "../assets/images/blue-clouds-footer.jpg" );

//$side-nav-background: $primary-color url( "../assets/images/blue-clouds.jpg" );



// --------------------------------------------------

// application

// --------------------------------------------------

$logo: url("../assets/images/NHSlogo2.png");



$app-max-width: 1400px;

$app-sub-nav-height: 50px;



$hspc-gutter: rem-calc(20);

$hspc-gutter-lg: rem-calc(50);

$hspc-button-medium: rem-calc(45);



$zindex-app: 1;

$modal-z-index: 2147483647;



$top-nav-section-height: 55px;



//** Disabled cursor for form controls and buttons.

$cursor-disabled: not-allowed !default;



// -------------------------

// isc-table

$table-th-size: rem-calc(12);

$table-td-size: rem-calc(12);

$pencil-button-color: #61ab45;

$remove-button-color: #e81d25;

$table-command-icon-width: rem-calc(35);

$nav-select-width: rem-calc(250);



// -------------------------

// general

$border-radius: rem-calc(3);

$form-input-height: rem-calc(45);



// $header-font-size: rem-calc(18);

$subheader-font-size: rem-calc(18);

$body-font-size-sm: rem-calc(12);

$hspc-button-height: rem-calc(45);

$isc-button-line-height: rem-calc(45);

$isc-button-height: rem-calc(45);

$isc-button-font-size: rem-calc(16);





// -------------------------

// timeline

$timeline-width-value : 250;

$timeline-width : $timeline-width-value + 0px;

$row-min-height : 75px;

