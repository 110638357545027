/* Pagination Settings */

// Structure
$pagination-link-padding:        0 0.625rem !default;
$pagination-margin:              1rem auto !default;
$pagination-item-spacing:        0.25rem !default; // Amount of separatation between pagination items.

// Normal State
$pagination-font-size:           1.125rem !default;
$pagination-font-weight:         $font-weight-normal !default;
$pagination-color:               $accent-color  !default;

// Hover State
$pagination-color-hover:         scale-color($anchor-font-color, $lightness: -14%) !default;
$pagination-background-hover:    transparent !default;

// Active State
$pagination-font-weight-active:  $font-weight-bold !default;
$pagination-background-active:   transparent !default;
$pagination-color-active:        $accent-color !default;

// Disabled State
$pagination-color-disabled:      $gray !default;
$pagination-background-disabled: transparent !default;

/* Pagination Styles */
@include exports(pagination) {
  .pagination {
    padding: 0;
    width: 100%;
    border-top: none;
    list-style-type: none;
    text-align: center;
    margin: dir-values($pagination-margin);

    @include bidirectional() {
      margin: flip-dir($pagination-margin);
    }

    li {
      min-width: none;
      max-width: none;
      height: auto;
      float: none;
      line-height: 1;
      display: inline-block;
      padding: dir-values($pagination-link-padding);
      margin: dir-values(0 $pagination-item-spacing 0 0);

      @include bidirectional() {
        padding: flip-dir($pagination-link-padding);
        margin: flip-dir(0 $pagination-item-spacing 0 0);
      }

      a {
        display: inline-block;
        text-decoration: none;
        line-height: 1;
        padding: 0;
        color: $pagination-color;
        font-weight: $pagination-font-weight;
        font-size: $pagination-font-size;
        border-radius: 0;
        text-align: center;

        &:hover {
          font-weight: $pagination-font-weight;
          background: $pagination-background-hover;
        }

        svg {
          fill: $pagination-color;
          vertical-align: middle;
        }
      }

      &.active {
        a {
          font-weight: $pagination-font-weight-active;
          line-height: 1;
        }
      }

      &.disabled {
        a {color: $pagination-color-disabled;}

        svg {fill: $pagination-color-disabled;}
      }

      &.active {

        a {
          color: $pagination-color-active;
          background: $pagination-background-active;
          font-weight: $pagination-font-weight-active;
          line-height: 1;
        }
      }

      @include ltr() {
        &:last-child {
          margin-#{$global-right}: 0;
        }
      }
      @include bidirectional() {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  button, a.button, a {

    &.pagination {
      background: transparent;

      svg {
        display: inline;
        vertical-align: middle;
        margin-top: 0px;
        fill: $button-background;

        &:hover, &:active, &:focus {
          background: transparent;

          svg {
            fill: smartscale($button-background, 10%);
          }
        }
      }
    }
  }
}
