.contact-info {

  .call-options {
    padding-top: rem-calc(20);

    .clear-defaults-button{
      margin-top: rem-calc(20);
    }
  }

  .row-block{
    border-top: 1px solid $app-border-color;
    margin-top: rem-calc(5);
    text-align: left;
    color: black;
    font-size: rem-calc(14);
  }


}