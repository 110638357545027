// Components

$categories: 'AdvanceDirectives', 'Alerts', 'Allergies', 'Appointments', 'CarePlans', 'Contacts', 'DeviceObservations', 'Diagnoses', 'Documents', 'Encounters', 'FamilyHistories', 'Goals', 'HealthConcerns', 'IllnessHistories', 'Immunizations', 'Instructions', 'Insurances', 'LabOrders', 'Medications', 'Observations', 'OtherOrders', 'PhysicalExams', 'Problems', 'Procedures', 'ProgramMemberships', 'Providers', 'RadOrders', 'Referrals', 'SocialHistories';
$base-color: hsl(0, 70%, 30%);

// Generates colors for each category by rotating around the color wheel
@each $category in  $categories {
  $i: index($categories, $category);
  $category-color: adjust-color($base-color, $hue: $i * 12, $saturation: $i % 2 * 30, $lightness: $i % 3 * 20);

  .color-bar.#{$category} {
    background-color: $category-color;
  }

  .color-title.#{$category}:before {
    background-color: $category-color;
  }

  .#{$category}-text {
    color: $category-color;
  }
}

.hspc-form-error-message {
  @include fade-in-blink-out();
  color: red;
  font-size: rem-calc(14);
  overflow-y: hidden;
  padding-bottom:rem-calc(5);
  margin-bottom:rem-calc(5);
}

.hspc-form-success-message {
  @include fade-in-blink-out();
  color: $success-color;
  font-size: rem-calc(14);
  overflow-y: hidden;
  padding-bottom:rem-calc(5);
  margin-bottom:rem-calc(5);

  .fa-close{
    padding: 0 5px;
  }
}

@mixin color-bar() {
  height: 20px;
  width: 4px;
  border-radius: 2px;
  display: inline-block;
  float: $global-left;

  margin-#{$global-right}: 7px;

  &.circle {
    height: 11px;
    width: 11px;
    border-radius: 50%;
  }
}

.color-bar {
  @include color-bar();
}
