$line-width : 1px;

.isc-chart {

  user-select: none;

  .hspc-back-button {
    @include button(medium, false, $secondary-color,
      scale-color($secondary-color, $lightness: -15%), auto);
    margin: 0;
    min-height : 45px;
    transform: scaleX(-1);

    svg {
      padding: 0;
      fill   : $primary-color;
      height : 20px;
      width  : 20px;
    }
  }

  .chart-controls {
    flex-wrap     : nowrap;
    margin-bottom : rem-calc(10);

    .grid-block {
      flex-wrap: nowrap;
    }

    .chart-buttons {
      justify-content : inherit;

      @include breakpoint (tablet up) {
        justify-content : space-around;
      }
    }

    .scrub-container {
      text-align : center;
      overflow   : hidden;

      @include breakpoint(tablet) {
        margin : 0 rem-calc(15);
      }
    }

    .datepicker-container {
      .moment-picker-container th,
      .moment-picker-container td {
        display: table-cell;
      }

      // mobile
      @include breakpoint(tablet down) {
        // LTR languages
        .moment-picker.left .moment-picker-container {
          left: 10px;
          // positions arrow
          &:before, &:after {
            left: 30px;
          }
        }
        // RTL languages
        .moment-picker.right .moment-picker-container {
          right: 10px;
        }

        isc-datepicker {
          margin-bottom: 2rem;
        }

        margin-top : rem-calc(20);

        label:before {
          content        : "\f073";
          font-family    : FontAwesome;
          position       : relative;
          font-size      : rem-calc(20);
          color          : $primary-color;
          pointer-events : none;

          margin-#{$global-right} : rem-calc(10);
          margin-#{$global-left}  : 0;

          @include bidirectional() {
            margin-left  : rem-calc(10);
            margin-right : 0;
          }
        }
      }

      // desktop
      @include breakpoint( tablet ){
        // LTR languages
        .moment-picker.left .moment-picker-container {
          left: -50px;
          // positions arrow
          &:before, &:after {
            left: 70px;
          }
        }
        // RTL languages
        .moment-picker.right .moment-picker-container {
          right: -50px;
          // positions arrow
          &:before, &:after {
            right: 70px;
          }
        }

        .chart-date {
          margin : 0;

          ._720kb-datepicker-calendar {
            order : 3;
            @include breakpoint(tablet) {
              margin-left : rem-calc(-33);
            }
          }

          &:before {
            content          : "\f073";
            font-family      : FontAwesome;
            position         : absolute;
            top              : rem-calc(8);
            font-size        : rem-calc(22);
            color            : $primary-color;
            pointer-events   : none;

            #{$global-right} : rem-calc(10);
            #{$global-left}  : auto;

            @include bidirectional() {
              left  : rem-calc(10);
              right : auto;
            }
          }

          input {
            margin-bottom : rem-calc(5);
            cursor        : auto;
          }

          label {
            margin-bottom : rem-calc(2);
          }
        }
      }

      // Native date input for mobile
      input.chart-date[type='date'] {
        min-width: 12rem;
      }

    }
  }

  .graph-container {
    clear         : both;
    margin-bottom : rem-calc(30);
    border-top    : $panel-border;

    .test-title {
      text-align    : center;
      margin-bottom : rem-calc(-60);
      padding-top   : rem-calc(20);
    }

    @include breakpoint(tablet down) {
      .test-title {
        margin-bottom : 0;
      }
      .point-data {
        padding-top : rem-calc(15);

        .grid-block {
          @include fade-in-blink-out();
        }
      }
    }
  }

  svg {
    pointer-events : none;

    .line, .scrub-line {
      fill            : transparent;
      stroke          : $primary-color;
      stroke-linejoin : round;
    }

    .line-group .point-group circle {
      &.point-primary {
        fill : $primary-color;
      }
      &.point-danger {
        fill : $alert-color;
      }
    }

    .line {
      stroke-width : $line-width;
    }

    .scrub-line {
      stroke-width : $line-width;
    }

    .hover-capture {
      fill   : none;
      cursor : pointer;
    }

    .high-box {
      fill         : rgba(190, 50, 50, 0.09);
      stroke       : rgba(190, 50, 50, 0.4);
      stroke-width : $line-width;
      text {
        font-size : rem-calc(11);
        fill      : black;
      }
    }

    .normal-box {
      fill         : rgba(1, 200, 100, .15);
      stroke       : rgba(1, 200, 100, .6);
      stroke-width : $line-width;
    }

    .low-box {
      fill         : rgba(190, 50, 50, .1);
      stroke       : rgba(190, 50, 50, 0.4);
      stroke-width : $line-width;
    }

    .domain {
      stroke-width : $line-width;
    }

    .x-axis, .y-axis {
      line, path {
        fill            : none;
        stroke          : #000;
        stroke-width    : $line-width;
        shape-rendering : crispEdges;
      }
    }

    .scrubber {
      .x-brush {
        .resize rect {
          visibility : visible !important;
          fill       : $primary-color;
          opacity    : .4;
        }

        .extent {
          fill    : $primary-color;
          opacity : .15;
        }
      }
    }

    .hover-items {

      .x-hover, .y-hover, .data-point {
        stroke : $primary-color-dark;
        fill   : $primary-color-dark;
      }

      .tooltip-group {

        .tooltip-rect {
          fill           : white;
          stroke         : $primary-color;
          rx             : 3px;
          ry             : 3px;
          width          : 220px;
          height         : 70px;
          pointer-events : none;
        }

        .tooltip-text {
          font-size : rem-calc(11);
          fill      : black;
        }
      }
    }
  }
}
