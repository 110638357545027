.isc-spinner {
  @include fade-in-out();

  border     : none;
  position   : fixed;
  width      : 100vw;
  height     : 100vh;
  z-index    : 1000;
  background : rgba(0, 0, 0, 0.2);

  i {
    margin     : auto;
    color      : rgba(0, 0, 0, 0.4);
    align-self : center;
  }

  h1 {
    align-self  : center;
    padding-top : rem-calc(20);
  }
}
