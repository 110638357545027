
@mixin timeline-width(){
  @include breakpoint(tablet) {
    width: $timeline-width;
  }
}

.time-cat-buttons {
  order: 0;
  button {
    margin-right: 0;

    @include breakpoint (tablet down) {
      margin-bottom: 1rem;
    }
  }
}

@import "../../../modules/healthRecords/timeline/hspc-timeline";