a {
  color: $brand-link;
  text-decoration: none;
  &:hover,
  &:active {
    color:darken( $brand-link, 15 );
  }

  &.light-blue {
    color: $brand-link-2;
    &:hover,
    &:active {
      color:darken( $brand-link-2, 20% );
    }
  }

  &.dark-blue {
    color: $brand-link-3;
    &:hover,
    &:active {
      color:darken( $brand-link-3, 20% );
    }
  }

  &[disabled]{
    @extend .button.disabled;
  }
}
