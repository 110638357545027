.hspc-sort-items {
  //margin-top:  -(rem-calc(1));
  box-sizing : border-box;
  padding    : rem-calc(10);
  border     : 1px solid $app-border-color;
  min-height : rem-calc(30);

  input[type='checkbox'] {
    margin-top : rem-calc(4);
  }

  .items {
    padding-#{$global-left} : rem-calc(10);
    @include bidirectional() {
      padding-right : rem-calc(10);
    }
  }

  &.disabled {
    background-color : $gray-dark;
  }

  .main {
    font-weight : 600;
    color       : $primary-color;
  }

  span {
    padding-bottom : rem-calc(5);
  }

  &:hover {
    background : $secondary-color;
  }

  &.hspc-max-selected-alert {
    background  : $warning-color;
    font-weight : bold;
  }
}

.hspc-modal.charts-list {
  aside {
    div.content {
      overflow-x : hidden;
    }
  }
}
