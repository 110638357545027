form {
  max-width: 100%;
}

h2{
  margin-bottom: rem-calc(20);
}

.display-question-block{
  label,
  .inline-label{
    font-weight: 400;
  }
}

.question-block{
  margin-bottom: rem-calc(10);
}

.grid-content{
  padding: 0;
  margin: rem-calc(20) 0 0;

  .cancel-button{
    margin-top: rem-calc(20);
  }

}