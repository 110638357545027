.hspc-filters {
  background-color : $secondary-color;
  border           : $panel-border;
  @include timeline-width();
  overflow         : hidden;

  .tab-item {
    align-items : center;
    white-space : normal;
    overflow    : hidden;
    padding     : 0.400rem;

    .checkbox {
      display         : flex;
      align-items     : center;
      justify-content : space-between;
      float           : left;

      input[type='checkbox'] {
        width      : 25px;
        align-self : center;
      }

      h3 {
        white-space : normal;
        align-self  : center;
        text-align  : left;
      }
    }

    .fa-spinner {
      margin-left:10px;
    }
  }

  .date-container {
    padding-left : 12px;
  }

  .header {
    .title {
      padding : 0.35rem 0.75rem;
    }

    .close-button {
      color    : darken($gray, 45%);
      position : relative;
      right    : 5px;
      top      : inherit;
    }
  }

  .no-left-right-border {
    border-left  : none;
    border-right : none;
  }

  .no-bottom-border {
    border-bottom : none;
  }

  .filter-title {
    padding-left : 0;
  }

  .date-picker-container {
    padding : 0.2rem 0 0.3rem 3rem;
  }

}
