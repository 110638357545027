.reviewPreferences {
  div {
    padding-bottom: 25px;
  }
    .languageContent {
      padding-left:50px;
    }

    .commLanguageSelection {
      padding-left:25px;
    }

    .small {
      i {
        color : white;
      }
        padding     : 10px;
        margin-left : 5px;
        margin-top : 2px;
    }

    h4 {
      color       : $gray-vdark;
      font-weight : 600;
      font-size   : 18px;
      overflow-wrap: break-word;
    }

    h3 {
      margin-bottom:0;
    }

    .communicationsDiv {
      padding-left:25px;
    .commLanguageSelection {
      padding-top: 10px;
    }
    .emailDiv, .phoneDiv {
      padding-left : 25px;
    .inline-block {
      display:inline-block;
    select {
      min-width:125px;
    }
    .hoverLabel {
      font-size     : 13px;
      margin-bottom : 3px;
      color         : $gray-vdark;
    }
    }
    button.margin-top {
      margin-top:25px;
    }
    .largeInput {
      max-width:350px;
    }
    }
    }


}
