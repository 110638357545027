.search {
  border: 1px solid $app-border-color;

  .section {
    padding: rem-calc(16);
    padding-bottom: 0;

    &.left {
      flex: 4 0 auto;
      @include breakpoint(tablet){
        padding-right: 0;
      }
    }

    &.right {
      flex: 1 0 auto;
      @include breakpoint(tablet down){
        padding-top: 0;
        padding-right: 0;
      }
    }

    .hspc-styled-select{
      margin: 0;
    }
  }

  .inline-label {
    width: 100%;
  }


}