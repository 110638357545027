
.warning-box-fade {
  @include fade-in-out();
}

.warning-box {
  cursor           : pointer;
  border           : 1px solid $app-border-color;
  background-color : $warning-color;
  margin-top       : $hspc-gutter;
  padding          : $hspc-gutter;
  min-height       : rem-calc(50);
  overflow         : hidden;

  .message {
    min-height : min-content;
    margin     : 0 $hspc-gutter 0 0;
  }

  p {
    margin-bottom : rem-calc(5);
  }
}
