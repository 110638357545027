.hspc-footer {

  background: $footer-background;
  width: 100%;
  .copyright, a {
    color: white;
    font-weight: 700;
    font-size: rem-calc(12);
    align-self: center;
    padding: 1rem;
  }
  .menu-bar{
    >li{
      >a{
        flex: 1 0 auto;
        &:hover {
          color: lighten ($primary-color, 10);
        }
      }
    }
  }

}
