.activate {
  @import "reviewPreferences/_reviewPreferences";
  // here is a comment
  activate-account,
  activate-terms,
  activate-user-account,
  create-security-questions,
  .content {
    display        : flex;
    flex-direction : column;
    flex           : 1 1 auto;
  }

  .content {
    margin-bottom : rem-calc(30);
  }

  .grid-container {
    max-width : none;
    margin    : 0;
  }

  form {
    max-width : 500px;
  }

  select {
    margin-top : rem-calc(0.5);
  }

  .text-info {
    margin : rem-calc(20) 0;

    &.grid-content {
      padding : 0;
    }
  }

  .form-section {
    padding-top : rem-calc(15);

    &:first-child {
      padding-top : 0;
    }
  }

  input[readonly] {
    background-color : #fff;
    cursor           : text;
  }

  .form-label {
    font-size     : rem-calc(16);
    margin-bottom : rem-calc(20);
  }

  .instructions {
    margin     : 0 0 rem-calc(20) 0;
    font-style : italic;
  }

  .dob {
    padding-bottom : rem-calc(40);
  }

  .mmn {
    padding-top   : rem-calc(10);
    margin-bottom : rem-calc(10);
  }

  .ssn {
    margin-bottom : rem-calc(5);
  }

  .fpw-radio-row {
    padding-left  : rem-calc(5);
    margin-bottom : rem-calc(10);
    flex          : 0 0 auto;
    line-height   : 2;
  }

  .pw-info {
    margin-top : rem-calc(20);
  }

  .response-message {
    color : $alert-color;
  }

  .reset-pw-message {
    @extend .response-message;

    margin-top    : rem-calc(10);
    margin-bottom : rem-calc(20);
  }

  .help-text {
    div {
      padding-bottom : rem-calc(20);

      ul {
        li {
          margin : rem-calc(10) 0 0 rem-calc(15);
        }
      }

    }
  }

  .help-params {
    font-size : rem-calc(10)
  }

  .ip-workflow-buttons {
    @extend .workflow-buttons;

    flex : 0 0 auto;

    // We had to override some default button styles here
    .section {
      @include breakpoint(phone) {
        flex-direction : column;

        .button {
          margin-bottom : 0;

          &:first-child {
            margin-bottom : rem-calc(10);
          }
        }

      }

      @include breakpoint(phablet) {
        flex-direction : row;

        .button {
          &:first-child {
            margin-bottom : 0;
          }
        }
      }
    }
  }
}
