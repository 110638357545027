/*
  FORMS
  -----

  Basic styles for timeline component.

  Includes:
   - Horizontal Step timeline used for forms that display in a stepped wizard format.
   - TO DO: Vertical Timeline
*/

/* Timeline Settings */
$timeline-margin: 0 0 3.5rem 0 !default;
$timeline-color: $primary-color !default;
$timeline-circle-size: 2.5rem !default;

/* Timeline Styles */
@include exports(timeline) {

  /* Wizard Step Timeline */
  .wizard-timeline {
    display: flex;
    margin: $timeline-margin;

    @include bidirectional() {
      padding: flip-dir($timeline-margin);
    }

    .circle {
      background-color: $timeline-color;
      background-size : $timeline-circle-size $timeline-circle-size;
      border-radius: 50%;
      width: $timeline-circle-size;
      height: $timeline-circle-size;
      position: relative;
      margin-top: 0;
      color: isitlight($timeline-color, $body-font-color, $light-text);
      text-align: center;
      font-size: 1.5rem;
      padding: 0.125rem;
      line-height: 1;
      vertical-align: middle;
      margin-#{$global-right}: 1rem;
      border: 3px solid $timeline-color;
      z-index: 1;

      @include bidirectional() {
  			margin-right: 1rem;
  		}

      button {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
        font-weight: $font-weight-semi-bold;
      }

      &.hollow {
        background-color: isitlight($timeline-color, $body-font-color, $light-text);

        button {
          color: $timeline-color;
        }
      }
    }

    .line {
      padding-top: 0;

      &:before {
        content: "";
        position: absolute;
        height: 0.25rem;
        width: 100%;
        background: $timeline-color;
        top: 50%;
        #{$global-left}: 0px;
        z-index: 0;

        @include bidirectional() {
    			right: 0px;
    		}
      }
    }
  }

}
