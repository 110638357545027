/* Page Structure */
$vertical-container-padding: 2rem !default;
$horizontal-container-padding: 1.5rem !default;
$horizontal-container-padding-small: 1rem !default;
$isc-page-padding: 2rem 1.5rem 2rem 1.5rem !default;
$isc-page-padding-medium: 2rem 1rem 2rem 1rem !default;
$isc-page-padding-small: 2rem 1rem 2rem 1rem !default;

/* Section Structure */
$section-block-padding: 1rem 0 0 0 !default;
$section-block-margin: 0 0 2rem 0 !default;
$subsection-padding: 2rem 0 0 0 !default;
$subsection-margin: 0 0 2rem 0 !default;
$section-title-margin: 0 0 2rem 0 !default;

@include exports(layout) {
  .isc-app-content {
    height: 100%;
    flex: 1 1 auto;
  }

  .grid-container {
    &.contain-left {
      margin: dir-values(0 auto 0 0);

      @include bidirectional() {
        margin: 0 0 0 auto;
      }
    }

    &.contain-right {
      margin: dir-values(0 0 0 auto);

      @include bidirectional() {
        margin: 0 auto 0 0;
      }
    }
  }

  /* Page Architecture */

  /* Built-In Page Padding */
  .isc-page {
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    overflow-x: hidden;

    @media #{$large-up} {
      padding: dir-values($isc-page-padding);

      @include bidirectional() {
        padding: flip-dir($isc-page-padding);
      }
    }

    @media #{$medium} {
      padding: dir-values($isc-page-padding-medium);

      @include bidirectional() {
        padding: flip-dir($isc-page-padding-medium);
      }
    }

    @media #{$small} {
      padding: dir-values($isc-page-padding-small);

      @include bidirectional() {
        padding: flip-dir($isc-page-padding-small);
      }
    }
  }

  #main {height: auto;}

  /* Content Architecture */

  // Section Blocks of page content
  .section-block {
    @include grid-block;
    @include grid-size(expand);
    @include grid-orient(vertical);
    @include grid-wrap(false);
    @include grid-align(center);
    @include grid-order(0);
    padding: dir-values($section-block-padding);
    margin: dir-values($section-block-margin);
    overflow: visible;

    @include bidirectional() {
      padding: flip-dir($section-block-padding);
      margin: flip-dir($section-block-margin);
    }

    &.top {padding-top: 0;}

    .section-title {
      margin: dir-values($section-title-margin);

      @include bidirectional() {
        margin: flip-dir($section-title-margin);
      }
    }
  }

  .sub-section {
    padding: dir-values($subsection-padding);
    margin: dir-values($subsection-margin);

    @include bidirectional() {
      padding: flip-dir($subsection-padding);
      margin: flip-dir($subsection-margin);
    }
  }

  .grid-content .grid-block {
    margin-#{$global-left}: 0;
    margin-#{$global-right}: 0;
  }

}
