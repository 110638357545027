.hspc-message {

  .subject-area{
    min-height: rem-calc(14)!important;
  }

  .message-attachment {
    align-content : center;
    align-items   : center;
    border        : $panel-border;
    background    : $secondary-color;
    padding       : 10px;
    margin-bottom : 5px;
  }

  .message-reply {
    margin : rem-calc(16) 0;

    .grid-content {
      padding : 0;
    }

    .bold {
      font-weight: 600;
      color      : darken( $gray-vdark, 5% );
    }

    .darkgrey {
      color     : darken( $gray-vdark, 10% );
      font-size : 11px;

    }
  }

  .option-block {
    border-top : 1px solid $app-border-color;
    margin-top : rem-calc(10);
    padding    : rem-calc(10);

    &.selected {
      background : $secondary-color;
      border     : 1px solid $app-border-color;
    }

    &:focus {
      outline : none;
    }

    .main {
      font-weight : 600;
    }

    .radio-block {
      padding-right : rem-calc(10);
    }
  }

  .options-buttons {
    margin-top : rem-calc(20);
  }

  .section {

    .item-label {
      padding-right : rem-calc(16);
      min-width     : 100px;
    }

    .message-body {
      white-space : pre-line;
      margin      : rem-calc(16) 0;
      padding-top : rem-calc(16);
      width       : 100%;
      border-top  : 1px solid $app-border-color;
      font-size   : rem-calc(14);
      color       : black;
      word-wrap   : break-word;

      // server-generated classes
      .hsp-AppointmentSummary {
        border-top      : 1px solid $app-border-color;
        margin          : rem-calc(16) 0;
        margin-bottom   : rem-calc(16);
        display         : flex;
        justify-content : space-between;
        flex-direction  : column;

        &:first-child {
          border-top : none;
        }

        .hsp-AppointmentLabel {
          margin-top  : rem-calc(10);
          font-weight : 600;
        }

        .hsp-AppointmentValue {
          margin-top  : rem-calc(6);
          font-weight : 400;
          color       : $gray-vdark;
        }
      }
    }

    table.confirm-table {
      th {
        text-align: #{$global-left};
        @include bidirectional() {
          text-align: #{$global-right};
        }
      }
    }
    // end message-body
  }
}
