/* Pagination Settings */

// Structure
$pagination-link-padding:              0 0.625rem !default;
$pagination-margin:                    1rem auto !default;
$pagination-item-spacing:              0.25rem !default; // Right margin to separate pagination items.

// Normal State
$pagination-font-size:                 1.125rem !default;
$pagination-color:                     $brand-link-3  !important;

// Hover State
$pagination-color-hover:               darken($brand-link-3, 20%) !important;
$pagination-background-hover:          transparent !default;

// Active State
$pagination-background-active:         transparent !default;
$pagination-color-active:              $brand-link-3 !important;

// Disabled State
$pagination-color-disabled:            $gray !default;
$pagination-background-disabled:       transparent !default;


/* Pagination Styles */
.pagination {
  padding: 0;
  width: 100%;
  border-top: none;
  list-style-type: none;
  margin: $pagination-margin;
  text-align: center;

  li {
    padding: $pagination-link-padding;
    min-width: none;
    max-width: none;
    height: auto;
    margin: 0 $pagination-item-spacing 0 0;
    float: none;
    display: inline-block;

    a {
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      padding: 0;
      color: $pagination-color;
      font-weight: $font-weight-normal;
      font-size: $pagination-font-size;
      border-radius: 0;
      text-align: center;

      &:hover {
        font-weight: $font-weight-ultra-bold;
        background: $pagination-background-hover;
      }

      svg {
        fill: $pagination-color;
      }
    }

    &.active {
      a {
        font-weight: $font-weight-ultra-bold;
      }
    }

    &.disabled {
      a {
        color: $pagination-color-disabled;
      }

      svg {
        fill: $pagination-color-disabled;
      }
    }

    &.active {

      a {
        color: $pagination-color-active;
        background: $pagination-background-active;
        font-weight: $font-weight-ultra-bold;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

button, a.button, a {

  &.pagination {
    background: transparent;

    svg {
      display: inline;
      vertical-align: middle;
      margin-top: 0px;
      fill: $button-background;

      &:hover, &:active, &:focus {
        background: transparent;

        svg {
          fill: smartscale($button-background, 10%);
        }
      }
    }
  }

}
