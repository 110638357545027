.hspc-photo-thumbnail {
  border        : 1px solid $primary-color;
  padding       : 0;
  margin-bottom : 5px;
  width         : 100%;

  align-items   : center;

  .name {
    margin       : 7px 10% 7px 0;
    padding-left : 5px;
  }

  .progress-bar {
    @include fade-in-blink-out();
    margin  : 7px 5%;
    height  : 100%;
    padding : 0;
    border  : 1px solid $alert-color;

    .bar {
      height : 100%;
      width  : 1%;
      border : 2px solid $alert-color;
    }
  }

  .scanning-indicator {
    @include fade-in-blink-out();
    margin : 7px 10%;
  }

  .delete-button {
    @include fade-in-out();
    border-left      : 1px solid $primary-color;
    align-items      : center;
    background-color : $warning-color;
    color            : $primary-color;

    &:hover {
      background-color : darken($warning-color, 10);
      color            : lighten($primary-color, 10);;
    }

    i {
      padding : 7px;
    }
  }
}