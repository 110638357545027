// -------------
// generic table styles

table {
  width           : 100%;
  //border          : $panel-border;
  margin-bottom   : 0;
  border-collapse : collapse;
  white-space   : normal;

  .table-font {
    outline    : none;
    text-align : $th-text-align;
    @include bidirectional {
      text-align : right;
    }
    color      : $body-font-color;
    font-size  : rem-calc(12);
    padding    : rem-calc(15) rem-calc(8) rem-calc(15) 0;
  }

  thead {
    border : none;

    th {
      @extend .table-font;
      border-bottom : 1px solid $app-border-color;
      border-left   : none;
      border-right  : none;
      @include breakpoint(tablet down) {
        display : none;
      }

      a {
        text-align      : $th-text-align;
        @include bidirectional() {
          text-align : right;
        }
        color           : $body-font-color;
        font-size       : $table-th-size;
        font-weight     : 700;
        text-decoration : none;
        white-space     : nowrap;
        position        : relative;

        &.isc-clickable{
          padding-right:1rem;
          position:relative;
          &:after, &:before {
            font-family: FontAwesome;
            font-size: 0.75rem;
            opacity: 0.5;
            position:absolute;
            right:0;
            top: -2px;
            content: " \F106";// font-awesome unicode: angle-up
          }
          &:before {
            top:5px;
            content: " \F107";// font-awesome unicode: angle-down
          }
        }

        &.hspc-sortable {
          white-space: nowrap;
          color: $primary-color;
          &:before{
            display:none;
          }
          &:after {
            font-weight: 400;
            opacity: 1;
            top:1px;
            content: "  \F078";// font-awesome unicode: chevron-down
          }
          &.reverse:after {
            content: "  \F077";// font-awesome unicode: chevron-up
          }
        }

        &:hover {
          color : $primary-color;
        }

        &:focus {
          color : $primary-color;
        }

        &.selected,
        &.filtered {
          color : $primary-color;
        }

        &.selected:after {
          font-family : FontAwesome;
          font-size   : $table-th-size;
          font-weight : 400;
          content     : ' \F078'; // chev down
        }

        &.reverse.selected:after {
          font-family : FontAwesome;
          font-size   : $table-th-size;
          font-weight : 400;
          content     : " \F077"; // chev up
        }

        &.filtered:after {
          font-family : FontAwesome;
          font-size   : $table-th-size;
          font-weight : 400;
          content     : " \F0b0"; // fa filter
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom : 1px solid $app-border-color;
      border-top    : none;

      td {
        @extend .table-font;
        border-left  : none;
        border-right : none;

        @include breakpoint(tablet down) {
          padding-top : 0;
        }

        &.main {
          color       : $primary-color;
          font-weight : 700;
        }

        &.read {
          color      : darken( $gray-vdark, 15% );
          background : $secondary-color;
        }
      }
    }
  }
}

// end generic table styles

.isc-table-command-icon {
  max-width        : 30px;
  border           : none;
  background-color : white;
  padding          : 0 10px 4px 10px !important;
  cursor           : pointer;

  svg {
    display        : inline-block;
    vertical-align : middle;
    width          : 20px;
    height         : 20px;
    fill           : $pencil-button-color;
  }

  &:hover,
  &:disabled {
    svg {
      fill : $gray-dark;
    }
  }

  &:disabled {
    cursor : not-allowed;
  }
}

.isc-table-command-edit-icon {
  @extend .isc-table-command-icon;
}

.isc-table-command-remove-icon {
  @extend .isc-table-command-icon;
  svg {
    fill : $remove-button-color;
  }
}

.isc-table-command-save-icon {
  @extend .isc-table-command-icon;
  svg {
    fill : $button-primary;
  }
}

.isc-table-command-cancel-icon {
  @extend .isc-table-command-icon;
  svg {
    fill : $remove-button-color;
  }
}

.isc-table {

  table {
    border        : none;
    margin-bottom : 0;

    thead {
      border : none;
    }

    .cell-title {
      @include breakpoint(tablet down) {
        display : block;
      }
    }

    .word-wrap {
      text-align  : $global-left;
      @include bidirectional() {
        text-align : right;
      }
      white-space : pre;
    }
  }

  ul {
    &.pagination {
      margin : 1rem auto;
    }
  }
}

.fc {
  td {
    text-align : center;

    &.fc-th-td {
      font-size       : $table-th-size;
      font-weight     : 600;
      text-decoration : none;
      white-space     : nowrap;
    }
  }
}

// Fix for rtl overrides in isc-direction
.rtl table {
  td.fc-day-top, .fc-th-td.fc-day-header, td.fc-event-container {
    text-align : center;
  }
}

// Overwrite the max-width:100px
.hspc-app .healthRecords .synopsis .isc-table:not(.observations,.lab-orders) table td {
  &.summary-cell {
    max-width : 100%;
  }

  &.text-cell {
    max-width : 100%;
  }
}

// Overwrite the settings that end up in the moment-picker th cells being hidden
.hspc-app isc-datepicker .moment-picker table thead th {
  @include breakpoint(tablet down) {
    display : table-cell;
  }
}


