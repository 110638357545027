.myAccount {

  form {
    max-width : 600px;

    input[readonly] {
      background-color : #fff;
      cursor           : text;
    }
  }

  @import "directives/displaySecurityQuestion/hspc-display-security-question";

  span .clickable {
    color : $brand-link;

    &:hover,
    &:active {
      color : darken($brand-link, 15);
    }
  }

  .myaccount-title-prefix {
    padding-right : rem-calc(10);
  }

  .summary-content {
    margin-top : rem-calc(10);

    .summary-label {
      font-weight   : $font-weight-bold;
      margin-bottom : 0.75rem;
    }
  }

  label {
    font-size   : $subheader-font-size;
    font-weight : $font-weight-semi-bold;
  }

  [ng-bind-html] div {
    padding : .5rem 0;
  }

  table {
    border : none;

    @include breakpoint(tablet down) {

      tr {
        vertical-align : baseline;
      }
      th {
        display : none;
      }

      h3 {
        white-space   : nowrap;
        margin-bottom : 0;
      }
    }

    @include breakpoint (phablet down) {
      td {
        display : block;
      }
    }
  }

  .summary-block {
    margin-bottom : rem-calc(20);
  }

  .content {
    margin-top : rem-calc(15);
  }

  .device-section {
    $image-size : rem-calc(200);
    margin : rem-calc(30) 0 rem-calc(30);

    .device-container {
      padding : rem-calc(25);
      width   : $image-size;

      .device-image {
        width  : $image-size;
        margin : rem-calc(5) auto;

        img {
          height : $image-size;
          width  : $image-size;
        }
      }

      a {
        width : $image-size;
      }
    }
  }

  .show-password {
    margin-right : rem-calc(10);
  }

  h2 {
    margin-top : rem-calc(20px);
  }

  h3 {
    margin-bottom : rem-calc(20px);
  }

  .inline-label {
    margin-bottom : 0;
  }
  .ontrigger{
    display: none;
    background-color:darken($neutral-color, 10%);
  }
  .trigger {
    cursor:pointer;
    transition: background-color .25s ease-in-out, color .25s ease-in-out, font-weight .25s ease-in-out;
  }
  .trigger:hover {
    background-color: lighten($primary-color, 7%);
    td {
      color:white;
      font-weight:600;
      transition: color .5s ease-in-out, font-weight .5s ease-in-out;

    }
    transition: background-color .5s ease-in-out;
  }

  .preferences {
    input:not([type="checkbox"]), select {
      display        : inline-block;
      max-width      : 200px;
      vertical-align : top;
      padding-right  : 10px;
      margin-bottom  : 5px;
    }
    .passwordDiv input:not([type="checkbox"]) {
      display: block;
    }

    .alert {
      color: $alert-color;
    }

    .languageContent {
      padding-left:50px;
    }

    .commLanguageSelection {
      padding-left:25px;
    }

    .small {
      i {
        color : white;
      }
      padding     : 10px;
      margin-left : 5px;
      margin-top : 2px;
    }

    h4 {
      color       : $gray-vdark;
      font-weight : 600;
      font-size   : 18px;
      overflow-wrap: break-word;
    }

    h3 {
      margin-bottom:0;
    }

    .communicationsDiv {
      padding-left:25px;
      .commLanguageSelection {
        padding-top: 10px;
      }
      .emailDiv, .phoneDiv {
        padding-left : 25px;
        .inline-block {
          display:inline-block;
          select {
            min-width:125px;
          }
          .hoverLabel {
            font-size     : 13px;
            margin-bottom : 3px;
            color         : $gray-vdark;
          }
        }
        button.margin-top {
          margin-top:25px;
        }
        .largeInput {
          max-width:350px;
        }
      }
    }


    .passwordDiv {
      padding-top:20px;
      &::before {
        content: '* ';
        color: $alert-color;
      }
      .largeInput {
        max-width:350px;
        cursor: text;
        background-color: #FFFFFF;
      }
    }

    @media only screen and (max-width:650px) {
      .largeScreen {
        display : none;
      }
      .smallScreen {
        padding-top:10px;
      }
      .communicationsDiv {
        padding-left:0;
      }

      .commLanguageSelection, .languageContent {
        padding-left:25px;
      }

    }

    @media only screen and (min-width:650px) {
      .smallScreen {
        display:none;
      }
    }



  }
  .hspc-table-button{
    min-width  : 20px;
    min-height : 20px;
    svg {
      height : 18px;
      width  : 18px;
    }
  }

}
