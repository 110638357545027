

.hspc-header {

  z-index : 10;



  //border: 2px solid red !important;



  // --------------------------

  // phone proxy sticky nav

  // --------------------------



  .proxy-container {

    background      : black;

    background-size : cover;

    height          : $top-nav-section-height;

    max-height      : $top-nav-section-height;

    padding         : rem-calc(5) 0;

    align-items     : center;

  }



  // --------------------------

  // END phone proxy sticky nav

  // --------------------------



  .component-label {

    padding-right : rem-calc(10);

    &.white {

      color : white;

    }

  }



  .user {

    align-self : center;

    span {

      color         : white;

      padding-right : rem-calc(10);

    }

  }



  .proxy {

    // align-items: center;

    padding-right : rem-calc(20);



    label {

      font-size                : rem-calc(16);

      color                    : white;

      padding-#{$global-right} : rem-calc(10);

      @include bidirectional {

        padding-left : rem-calc(10);

      }

      margin-bottom            : 0;

    }

  }



  .language {

    margin : 0 rem-calc(20);

  }



  .left-menu {



    padding-top : rem-calc(10);



    @include bidirectional {

      margin-left : rem-calc(20);

    }



    @include breakpoint(phablet) {

      @include grid-size(shrink);

    }

  }



  .right-menu {

    @include breakpoint(phone) {

      align-self : right;

      max-width  : 100%;

    }



    .hamburger {

      justify-content : flex-end;



      button {

        @include invisible-button();

        outline    : none;

        padding    : 0;

        margin     : 0;

        background : transparent;

        border     : none;



        @include breakpoint(phone only) {

          margin : 0 rem-calc(10);

        }



        &:focus {

          outline : none;

        }

      }



      .hamburger-svg, svg {

        fill   : white;

        height : 40px;

        width  : 40px;

      }

    }



    .help {

      height  : 100%;

      cursor  : pointer;

      cursor  : hand;

      outline : none;



      .help-icon {

        outline     : none;

        text-align  : center;

        padding     : 0 rem-calc(25);

        font-weight : bold;

        color       : $primary-color;

        height      : 100%;

        width       : rem-calc(30);

        max-width   : 30px;

        background  : none;

        margin      : 0;



        @include ltr(){

          font-family : 'Open Sans';

          line-height : 140%;

          font-size   : rem-calc(35);

        }



        @include rtl(){

          font-family : 'Helvetica Neue';

          line-height : 140%;

          font-size   : rem-calc(35);

        }



        @include breakpoint (phone){

          border-#{$global-left}  : rem-calc(3) solid white;

          @include bidirectional {

            border-right : rem-calc(3) solid white;

            border-left  : none;

          }

        }



        margin-#{$global-right} : rem-calc(10);

        @include bidirectional {

          margin-left : rem-calc(10);

        }



        @include breakpoint (medium) {

          background             : $accent-color;

          padding                : 0 rem-calc(7);

          font-size              : rem-calc(25);



          border-#{$global-left}  : 0 solid transparent;

          @include bidirectional {

            border-right : 0 solid transparent;

          }



          margin-#{$global-left} : rem-calc(10);

          @include bidirectional {

            margin-right : rem-calc(10);

          }

        }

      }

    }

  }



  .nav-bar-tabs {

    overflow : visible;

    width    : 100%;



    .tab-bar {

      @extend .tab-nav;

      background              : $secondary-color;

      border                  : $panel-border;

      border-#{$global-right} : none;

      @include bidirectional {

        border-left : none;

      }

      z-index                 : 100;



      @include breakpoint(desktop) {

        padding : 0 $hspc-gutter-lg;

      }



      @include breakpoint(tablet only) {

        padding : 0 $hspc-gutter;

      }

    }



    .log-in-out-bar {

      @extend .tab-bar;

      border                 : $panel-border;



      border-#{$global-left} : none;

      @include bidirectional {

        border-right : none;

      }



      justify-content        : flex-end;

      align-items            : center;



      .log-in-out-button {

        @extend .tab-item;

        border : none;

        right  : 0;



        &.active,

        &:hover:not(.active) {

          border     : none;

          background : none;

          color      : black;

        }

      }

    }

  }



}

