.hspc-health-records-details {

  .hspc-back-button {
    @include button(medium, false, $secondary-color,
      scale-color($secondary-color, $lightness: -15%), auto);
    margin: 0;
    min-height : 45px;
    transform: scaleX(-1);

    svg {
      padding: 0;
      fill   : $primary-color;
      height : 20px;
      width  : 20px;
    }
  }

  &.devices {
    margin-top: 20px;
  }

  .device-table{
    margin-top: 20px;
  }

  .grid-block{
    margin: 0;
  }

  td {

    &.column{
      white-space: normal;
      overflow: hidden;
      hyphens: auto;
      word-break: break-all;
      word-wrap: break-word;
      padding-left: 5px;
    }
  }

  svg {
    width  : 2rem;
    height : 1rem;
  }

  .metadata {
    height: rem-calc(50);
  }

  .label {
    font-weight: $font-weight-bold;
    background-color: transparent;
    color: $body-font-color;
    display: table-cell;
    font-size: $table-td-size;
  }

  .html {
    display: block;
    clear: both;
    margin-top: rem-calc(20);

    .html-label {
      font-weight: 700;
      line-height: 3;
      padding-left: 0;
    }
  }

  .comments {
    font-style: italic;
  }

  .data-info {
    padding-top    : rem-calc(15);
    padding-#{$global-left}: rem-calc(10);
    @include bidirectional() {
      padding-right : rem-calc(20);
    }
    color                  : darken( $gray-vdark, 15% );

  }

  .bold {
    font-weight: 900;
  }
}

.personal-information .hspc-health-records-details {
  td.label {
    width: 30%;
  }
}
