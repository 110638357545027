/**
bread crumb style
**/

.breadcrumb-container{

  margin: rem-calc(20) 0 0 0;

  .grey-bar{

    display: block;
    background-color: #D3D3D3;
    height: 6px;
    position: relative;
    left: 10px;
    top: -13px;
    z-index: -100;
  }

  .bg-purple{
    background-color: rgb(94,	77,	183	)!important;
  }

  .breadcrumb-item{
    z-index:0;
    width:100%;
  }

  .breadcrumb-item:last-of-type{
    width:13%;
  }

  .grey-circle{
    text-align: center;
    color:rgba(255,255,255,0.8);
    z-index: 100;
    width: 1.3rem;
    height: 20px;

    background-color: #D3D3D3;
    background-size: cover;
    border-radius: 100px;
    padding-top: 1px;
  }

  .grey-circle:not(:first-child) {
    margin-left: -5px;
  }
}