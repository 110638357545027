.hspc-in-out-boxes {

  margin: 0;
  padding: 0;

  &.grid-container {
    max-width: none;
  }

  .custom-content{
    padding-top: 1.25rem;
  }

  .mail-box {
    margin: 0;
    padding: 0;

    .top-buttons {
      border-top: 1px solid $app-border-color;
      padding-top: $hspc-gutter;
      margin: $hspc-gutter 0 0 0;
    }

    .no-messages-warning {
      border: 1px solid $app-border-color;
      background: $secondary-color;
      padding: rem-calc(10);

      p {
        color: $dark-color;
      }
    }

    .read-message-icon {
      visibility : hidden;
      color      : $primary-color;
      font-size  : 12px;

      &.visible {
        visibility: visible;
      }
    }

    .mail-table {
      padding: 1rem 0;

      table {border: none;}

      input[type=checkbox]{
        margin-left: rem-calc(10);
      }
      label {
        span {
          display:none;
        }
      }
    }
    // end mail-table
  }
  // end mail-box

  .mobile-select-all {
    margin: rem-calc(10);
    label {
      margin-bottom:0;
    }
  }

  .mobile-message {
    border-top: 1px solid $app-border-color;
    padding: rem-calc(10);

    .left {
      margin-right: rem-calc(10);
      align-items: flex-start;

      .read-message-icon {
        margin-top: -(rem-calc(3));
        padding: rem-calc(5);
      }
    }

    .right {
      outline: none;
      span {
        padding-bottom: rem-calc(5);

        &.main {
          color: $primary-color;
          font-weight: 700;
        }
      }
    }

    &.read {
      color: darken( $gray-vdark, 10% );
      background: $secondary-color;
      .right {
        .main {
          color: darken( $gray-vdark, 10% );
        }
      }
    }
  }
  // end mobile-message

}
