table {
  tbody {
    tr {
      td {

        @include breakpoint(phablet down) {
          display : block;
        }

        @include breakpoint(tablet up) {
          padding : rem-calc(10) 1% rem-calc(10) 0;
        }

        @include breakpoint(phablet only) {
          padding : rem-calc(15) 1% rem-calc(10) 0;
          h3 {
            font-size   : rem-calc(13);
            font-weight : 700;
            color       : $primary-color;
          }
        }

        @include breakpoint(phone only) {
          padding : rem-calc(10) rem-calc(10) rem-calc(10) 0;
          h3 {
            font-size   : $table-font-size;
            font-weight : 700;
            color       : $primary-color;
          }
        }

        h3 {
          white-space : normal;
        }
      }
    }
  }

  @include breakpoint(tablet down) {
    th {
      display : none;
    }

    tr {
      vertical-align : baseline;
    }
  }

  .hspc-sortable {
    white-space : nowrap;
    &:after {
      font-family : FontAwesome;
      font-size   : $table-th-size;
      font-weight : 400;
      content     : " \F078";
    }
    &.reverse:after {
      content : " \F077";
    }
  }

  .hspc-filterable {
    white-space : nowrap;
    &:after {
      font-family : FontAwesome;
      font-size   : $table-th-size;
      font-weight : 400;
      content     : " \f0b0";
    }
  }
}

$current-toggle-margin : 0 rem-calc(10) 0 0;
$current-toggle-margin-sm : 0 rem-calc(10) rem-calc(-50) 0;

.current-toggle {
  display         : flex;
  justify-content : flex-end;

  margin          : dir-values($current-toggle-margin);
  @include bidirectional() {
    margin : flip-dir($current-toggle-margin);
  }

  @include breakpoint(phablet) {
    margin : dir-values($current-toggle-margin-sm);
    @include bidirectional() {
      margin : flip-dir($current-toggle-margin-sm);
    }
  }

  label {
    font-size   : rem-calc(16);
    text-align  : right;
    font-weight : normal;
  }
}

.plan-of-care {

  > .grid-block {
    border-top : 1px solid $gray-dark;

    span {
      margin : rem-calc(5) 0
    }

    button {
      max-height : 45px;
      margin     : auto 0;
    }
  }
}

.synopsis, .summary {
  // Using same color class names as property of API return
  $categories : 'Alerts', 'CarePlans', 'Instructions', 'LabOrders', 'Appointments', 'Allergies', 'Observations', 'RadOrders', 'Referrals';

  // Generates colors for each category by rotating around the color wheel
  @each $category in $categories {
    $i : index($categories, $category);

    ##{$category} {
      order : $i;
    }
  }

  .filter-bar {
    margin     : rem-calc(10) 0;
    align-self : center;

    @include breakpoint (phone only) {
      margin     : 0;
      align-self : unset;
    }
  }
}

.synopsis {

  .isc-table:not(.observations,.lab-orders) {
    @include grid-block(
      $size : shrink,
      $orientation : vertical
    );
    overflow   : hidden;
    border     : 1px solid $app-border-color;
    margin-top : rem-calc(30);

    h1 {
      padding          : rem-calc(5) 1%;
      background-color : $secondary-color;
    }

    table {
      border : none;

      th, td {
        padding-left : 1%;
        border-left  : none;
        border-right : none;
        max-width    : 100px;
        a {
          white-space : normal;
        }
      }
      .details-cell {
        text-align    : right;
        padding-right : 50px;
      }
    }
  }
}

.details-info {
  margin-top : rem-calc(30);
}

.summary {

  .tables-box {

    .color-title:before {
      @include color-bar();
      content        : "";
      float          : none;
      vertical-align : -2px;
    }

    .isc-table {
      margin-top : rem-calc(20);

      table {
        border : none;
      }

      thead {
        display : none;
      }

      tbody {
        width : auto;

        tr {
          width   : auto;
          display : table-row;

          .summary-cell {
            cursor    : pointer;
            min-width : 0;
            display   : table-cell;
            width     : auto;

            &.right {
              text-align : right;
            }

            span {
              display : block;
            }
          }

          td {
            padding : rem-calc(15) 0;
          }
        }
      }
    }

    @include breakpoint (phone only) {
      max-height : none;
      .isc-table {
        width : 100%;
      }
    }

    @include breakpoint (phablet only) {
      max-height : 850px;
      .isc-table {
        width : 340px;
      }
    }

    @include breakpoint (tablet only) {
      max-height : 850px;
      .isc-table {
        width : 300px;
      }
    }

    @include breakpoint (desktop) {
      max-height : 850px;
      .isc-table {
        width : 370px;
      }
    }
  }
}

.summary-cell {
  &.right {
    text-align : right;
  }

  &.center {
    text-align : center;
  }

  @include breakpoint (phone only) {
    &.right {
      text-align : left;
    }

    &.center {
      text-align : left;
    }
  }

  span {
    &.first-item-in-summary-cell {
      margin-right : 1rem;
    }
    &.middle-item-in-summary-cell {
      margin-left : 1rem;
    }
    &.last-item-in-summary-cell {
      margin-left : 1rem;
    }
  }
}

.hspc-back-button {
  @include button(medium, false, $secondary-color,
    scale-color($secondary-color, $lightness : -15%), auto);
  margin     : 0;
  min-height : 45px;
  transform  : scaleX(-1);

  svg {
    padding : 0;
    fill    : $primary-color;
    height  : 20px;
    width   : 20px;
  }
}

.personal-information {
  .my-details {
    margin-top : rem-calc(10);
  }
  .contact-details {
    margin-top : rem-calc(10);
    padding    : 0;
  }
  .details-title {
    font-weight : $font-weight-bold;
  }
  .deceased-box {
    background-color : lighten($primary-color, 50%);
    border           : 1px solid $app-border-color;
    margin-bottom    : rem-calc(10);
    margin-top       : $hspc-gutter;
    min-height       : rem-calc(50);
    overflow         : hidden;
    padding          : $hspc-gutter;
  }
  .contact-details-header {
    margin-bottom       : 0.5rem;
  }
  .icon_linkToAccountSummary {
    @include button(medium, false, $secondary-color,
      scale-color($secondary-color, $lightness : -15%), auto);
    margin     : 0;
    min-height : 35px;
    min-width  : 35px;
    svg {
      background-color : $secondary-color;
      fill             : $primary-color;
      height           : 25px;
      width            : 25px;
    }
  }
  .linkToAccountSummary {
    background-color : $secondary-color;
    border           : 1px solid $app-border-color;
    margin-bottom    : 0.75rem;
    .link {
      color     : $primary-color;
      font-size : rem-calc(16);
      padding   : rem-calc(8);
    }
  }
  .summary-label {
    font-weight   : $font-weight-bold;
    font-size     : 18px;
    margin-bottom : 0.75rem;
    span {
      padding-bottom : 0;
    }
  }
  .summary-block {
    margin-bottom : rem-calc(20);
    span {
      padding-bottom : rem-calc(6);
    }
    div {
      padding-bottom : rem-calc(6);
    }
  }
  .contacts {
    max-width : 50%;
    min-width : 50%;
    @media only screen and (max-width : rem-calc(639)) {
      max-width : 100%;
    }
  }
  .contact-card {
    background-color : $neutral-two;
    border-radius    : 15px;
    box-shadow       : 5px 5px 1px gainsboro;
    margin-right     : rem-calc(24);
    margin-top       : rem-calc(12);
    .contact-name {
      font-weight   : $font-weight-bold;
      margin-bottom : rem-calc(6);
      padding-left  : rem-calc(6);
      padding-top   : rem-calc(10);
      word-wrap     : normal;
    }
    .contact-address {
      padding-left : rem-calc(12);
      padding-top  : remcalc(6);
      span {
        padding-bottom : rem-calc(6);
      }
    }
    .contact-email {
      padding-bottom : rem-calc(6);
      padding-left   : rem-calc(12);
    }
    .contact-phones {
      padding-left : rem-calc(12);
    }
  }
}


