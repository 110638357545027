@import 'hspc-login-form';



@include breakpoint(tablet down) {

  padding : 0;

}



[ng-bind-html] div {

  padding-left : 0;

}



img.app {

  width: 120px;

  height: 120px;

}



h3.lhac {

  padding-top: 20px;

}



p.lhac {

  padding-bottom: 1rem;

}



.lhac-notice {

  background-color: $secondary-color;

  border: $panel-border;

  margin: rem-calc(5);

  padding-left: rem-calc(20) !important;

  padding-right: rem-calc(20);

  border-color: #8d0012;

  border-style: solid;

  border-width: medium;

}



.lhac-featured {

  background-color: $secondary-color;

  border: $panel-border;

  margin: rem-calc(5);

  padding: rem-calc(20);

}



.updates-nav-button {

  color            : $dark-text;

  background-color : $secondary-color;

  border           : $panel-border;

  padding          : rem-calc(10) rem-calc(20);

  margin           : 0;



  .nav-updates {

    color       : $alert-color;

    margin-left : 5px;

  }



  p {

    margin-bottom : 0;

  }

}



.homepage-wrapper {

  flex-wrap : nowrap;

  &.hspc-wrap {

    flex-wrap : wrap;

  }

}



.hp-sidebar {

  @include breakpoint (tablet) {

    padding-right : rem-calc(20);

  }

}



.hp-title-blocks {

  background-color : $primary-color;

  padding          : rem-calc(15);



  .home-page-picture {

    height       : auto;

    margin-right : rem-calc(15px);

    flex         : 0 1 auto;



    .picture {

      flex : 0 0 auto;

    }

  }

}



.patientUpdates {

  padding : 15px;

}



.title-bar {

  border           : none;

  background-color : $primary-color;

  padding          : rem-calc(10) rem-calc(20);

  color            : white;



  h3 {

    color : white;

  }

}



.updates-container {



  .expandable {

    position : relative;

  }



  .expandable:not(.is-active):after {

    content     : "\f078"; // chevron down to indicate it is expandable

    font-family : FontAwesome;

    right       : 10px;

    top         : 16px;

    position    : absolute;

  }



  .is-active.expandable:after {

    content     : "\f077"; // chevron up to indicate it has been expanded

    font-family : FontAwesome;

    right       : 10px;

    top         : 16px;

    position    : absolute;

  }



  .padded {

    background-color : darken($neutral-color, 8%);

    border-bottom    : 1px solid darken($neutral-color, 20%);

    padding          : 15px;

  }



  .fa-stack {

    display  : inline;

    float    : right;

    height   : 0;

    left     : 85%;

    top      : 4px;

    position : relative;

    z-index  : 99;

    @media all and (max-width : 1275px) {

      left : 80%;

    }

    .fa {

      color     : white;

      height    : 0;

      font-size : 20px;

    }

    .fa-circle {

      color     : $alert-color;

      font-size : 15px;

      left      : 4px;

      top       : 10px;

    }



    &.proxyAlert {

      top  : -70px;

      left : 15px;

    }

  }



  .update-box {

    background-color : $gray-light;



    h3 {

      color : $primary-color;

    }



    a {

      @include fade-in-blink-out();

      border-bottom : 1px solid $app-border-color;

      color         : $primary-color;

      display       : flex;

      font-size     : rem-calc(14);

      font-weight   : bold;

      padding       : rem-calc(15) 0 rem-calc(10) rem-calc(10);

      &:hover,

      &:active {

        color : lighten($primary-color, 20%);

      }

    }

  }

}



section {

  padding : rem-calc(20);



  &.proxy-selection {

    padding : 0;

  }



  @include breakpoint (tablet down) {

    border-bottom : 1px solid $gray-light;

  }



  img {

    width : 100%;

  }



  h3 a {

    color           : $alert-color;

    text-decoration : none;



    &:hover,

    &:active {

      color : darken($brand-link, 15);

    }



    &:disabled {

      color  : $gray-light;

      cursor : not-allowed;

    }

  }



}



.welcome-info {



  h2 {

    margin-bottom : rem-calc(5);

  }



  p {

    margin-bottom : 0;

  }



  .last-login {

    margin-top : rem-calc(15);

    font-size  : rem-calc(12);

  }

}



.log-in-custom {

  @include breakpoint(tablet up) {

    section {

      padding-left : 0;

    }

  }

}



.proxy-selection {

  background-color : $secondary-color;

  margin-top       : rem-calc(15);



  .proxy-user-container {

    .proxy-user {

      padding : rem-calc(15);

      &:hover,

      &:active {

        background-color : white;

      }

    }

    .proxy-deactivated-user {

      cursor  : auto;

      padding : rem-calc(15);

      a {

        cursor : auto;

      }

    }

    span {

      font-size   : rem-calc(12);

      color       : $primary-color;

      font-weight : bold;

    }



    .proxy-user-info {

      border-bottom   : 1px solid $app-border-color;

      display         : flex;

      flex-direction  : row;

      justify-content : space-between;

      padding         : rem-calc(15) 0 rem-calc(10) rem-calc(10);



      .fa-layers {

        display  : flex;

        float    : right;

        position : relative;



        .fa-bell-o {

          color     : black;

          font-size : rem-calc(25);

        }



        .fa-circle {

          bottom    : rem-calc(6);

          color     : $alert-color;

          font-size : rem-calc(18);

          float     : right;

          left      : rem-calc(15);

          position  : relative;

        }



        .text-layer-updates {

          bottom      : rem-calc(4);

          color       : white;

          font-size   : rem-calc(13);

          font-weight : bold;

          left        : rem-calc(-1);

          position    : relative;

        }

        .text-layer-updates-single {

          bottom      : rem-calc(4);

          color       : white;

          font-size   : rem-calc(13);

          font-weight : bold;

          left        : rem-calc(3);

          position    : relative;

        }



      }

      .fa-user {

        color         : black;

        display       : flex;

        font-size     : rem-calc(25);

        padding-right : rem-calc(3);

      }



      .no-updates-or-deactivated {

        display  : flex;

        float    : right;

        opacity  : 0;

        position : relative;

      }



      .principal-text-info {

        display : flex;

      }

      .principal-name {

        color       : $primary-color;

        font-size   : rem-calc(14);

        font-weight : bold;

      }

      .principal-secondary-text {

        color         : $primary-color;

        font-size     : rem-calc(12);

        font-weight   : normal;

        margin-bottom : 0;

      }

    }



    .show-more-principals {

      float     : right;

      font-size : rem-calc(12);

      padding   : rem-calc(10);

      position  : relative;

    }

  }

}



.proxy-only-proxy-selection {

  margin-top : rem-calc(15);

  padding    : 0;



  .for-proxy-only {

    padding   : rem-calc(15);

    // When we are in phablet and the cards wrap, we want them to all be the same width

    max-width : 50%;

    // When we are in phone mode, the cards are vertically aligned and so we want them to take up the whole screen,

    // not just 50% of it

    @media only screen and (max-width : rem-calc(639)) {

      max-width : 100%;

    }

    .proxy-only-proxy-user-container {

      background-color : $secondary-color;

      span {

        color       : $primary-color;

        font-size   : rem-calc(12);

        font-weight : bold;

      }

      .proxy-only-proxy-user-info {

        display         : flex;

        flex-direction  : row;

        justify-content : flex-start;

        padding         : rem-calc(15) 0 rem-calc(10) rem-calc(5);

        .fa-user {

          color         : black;

          display       : flex;

          font-size     : rem-calc(25);

          padding-right : rem-calc(8);

        }

        .proxy-only-principal-name {

          color       : $primary-color;

          display     : flex;

          font-size   : rem-calc(18);

          font-weight : bold;

        }

        .proxy-only-principal-secondary-text {

          color       : $primary-color;

          display     : flex;

          font-size   : rem-calc(12);

          font-weight : normal;

        }

      }

      .proxy-only-updates {

        .proxy-only-updates-text {

          color       : $brand-link;

          font-size   : rem-calc(15);

          font-weight : normal;

          padding     : rem-calc(3);

          &:hover,

          &:active {

            color : darken($brand-link, 15);

          }

        }

        .proxy-only-no-updates-text {

          color       : black;

          font-size   : rem-calc(15);

          font-weight : normal;

          padding     : rem-calc(3);

        }

      }



      .proxy-user {

        padding      : rem-calc(20);

        padding-left : rem-calc(9);

        &:hover,

        &:active {

          background-color : white;

        }

      }

      .proxy-deactivated-user {

        cursor  : auto;

        padding : rem-calc(15);

        a {

          cursor : auto;

        }



      }

    }

  }



}



.common-tasks {

  background-color : $secondary-color;

  border           : 1px solid $app-border-color;



  .common-tasks-icons {

    width : rem-calc(300);

  }

  .common-task-row {

    align-items     : left;

    display         : flex;

    flex-direction  : row;

    justify-content : space-between;

    padding-right   : rem-calc(40);



    .common-tasks-icon {

      @include button(medium, false, $secondary-color,

        scale-color($secondary-color, $lightness : -15%), auto);

      display    : flex;

      margin     : 0;

      min-width  : 45px;

      min-height : 45px;

      svg {

        background-color : $secondary-color;

        fill             : $primary-color;

        height           : 35px;

        width            : 35px;



      }

    }

    .common-tasks-label {

      color     : $primary-color;

      display   : flex;

      font-size : rem-calc(18);

      padding   : 0;

    }

  }

  .title-bar {

    background-color : $secondary-color;

  }

}



.news {

  .news-row {

    margin : rem-calc(10) 0;

  }

}



.events {

  h2 {

    margin-bottom : rem-calc(5);

  }



  .events-row {

    margin : rem-calc(5) 0;



    span {

      color : black;

    }



    .events-block {

      align-items   : center;

      border        : 2px solid $primary-color;

      border-radius : $global-radius;

      color         : $primary-color;

      max-height    : rem-calc(50);

      max-width     : rem-calc(50);

      padding       : rem-calc(5);

      p {

        color         : $primary-color;

        font-size     : $body-font-size-sm;

        margin-bottom : 0;

      }



    }



    .event-details {



      margin-#{$global-left}: rem-calc(10);



      @include bidirectional() {

        margin-right : rem-calc(10);

      }



      h3 {

        color         : $alert-color;

        margin-bottom : 0;

      }



      p {

        color : black;

      }

    }

  }

}



.all-link {

  margin-top : rem-calc(20);

}



.reminder-box {

  border           : 1px solid $app-border-color;

  background-color : lighten($primary-color, 50%);

  margin-top       : $hspc-gutter;

  min-height       : rem-calc(50);

  overflow         : hidden;

  padding          : $hspc-gutter;



  .reminder-link {

    color       : $primary-color;

    font-weight : bold;

    &:hover,

    &:active {

      color : lighten($primary-color, 20%);

    }

  }

}



.message-box {

  background-color : $app-border-color;

  margin-top       : $hspc-gutter;

  min-height       : rem-calc(50);

  overflow         : hidden;

  padding-top      : rem-calc(10);



  .message-box-table {

    background-color : $secondary-color;

    cursor           : pointer;

    margin-top       : rem-calc(10);

    min-height       : rem-calc(50);

    overflow         : hidden;

    .grid-table {

      margin-bottom : 0;

      width         : 100%;

      .grid-block {

        border-left  : 0px;

        padding-left : $hspc-gutter;

      }

    }

  }



  .message-box-table-mobile {

    background-color : $secondary-color;

    cursor           : pointer;

    margin-top       : rem-calc(10);

    min-height       : rem-calc(50);

    .message-mobile-from_0 {

      font-size   : rem-calc(18);

      font-weight : bold;

      padding     : rem-calc(5) 0 rem-calc(5) rem-calc(20);

    }

    .message-mobile-from_1 {

      font-size : rem-calc(18);

      padding   : rem-calc(5) 0 rem-calc(5) rem-calc(20);

    }

    .message-mobile {

      font-size      : rem-calc(16);

      padding-bottom : rem-calc(5);

      padding-left   : rem-calc(25);

    }

  }



  .message-box-mobile-no-messages {

    background-color : $secondary-color;

    font-size        : rem-calc(16);

    font-style       : italic;

    margin-top       : rem-calc(10);

    min-height       : rem-calc(50);

    padding          : rem-calc(15) 0 rem-calc(5) rem-calc(20);

  }



  .message-box-title {

    color        : $primary-color;

    display      : inline;

    font-size    : rem-calc(18);

    padding-left : rem-calc(10);

  }



  .message-inbox-link {

    display       : inline;

    float         : right;

    font-size     : rem-calc(14);

    padding-right : $hspc-gutter;

    padding-top   : rem-calc(5);

  }



}



&.main-content {

  @include breakpoint(tablet down) {

    padding : 0;

  }

}



.login-title-idps {

  padding-left : 1em;

  h1 {

    color         : white;

    margin-bottom : 0.5rem;

  }

}



.idp-login-items button {

  margin-bottom : 0rem;

}



.onlyIdp svg {

  float : left;

}



.hspc-device-confirmation {

  padding-left : 1em;

}



.idp-enroll-items span {

  color        : white;

  padding-left : 1em;

}



.idp-enroll-items .enroll-div {

  padding-top : 0.5em;

}



.idp-enroll-items button {

  svg {

    float  : left;

    height : 60%;

    width  : 60%;

    @media only screen and (max-width : rem-calc(639)) {

      height : 100%;

      width  : 100%;

    }

  }

}



.button svg {

  height : 80%;

  width  : 80%;

  @media only screen and (max-width : rem-calc(639)) {

    height : 100%;

    width  : 100%;

  }

}



.separator {

  display       : flex;

  color         : white;

  align-items   : center;

  text-align    : center;

  margin-bottom : 0.25rem;

}



.separator::before, .separator::after {

  content       : '';

  flex          : 1;

  border-bottom : 1px solid white;

}



.separator::before {

  margin-right : .25em;

}



.separator::after {

  margin-left : .25em;

}



