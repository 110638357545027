.hspc-my-bills {
  h1 {
    max-height:50px;
  }
  .infoDiv {
    padding-bottom: 20px;
  }

  .loadingDiv, .errorDiv {
    color: darken( $gray-vdark, 5% );
  }

  .filter {
    width            : 200px;
    background-color : $neutral-three;
    padding          : 10px;
    label {
      display:inline-block;
      margin-right : 10px;
    }
  }

  .isc-table {
    padding-top:20px;
    table {
      .inactive {
        background-color: $neutral-two;
      }
      thead {
        tr {
          th:nth-child(n+4) {
            span {
              display:none;
            }
          }
        }
      }
    }
    td {
      a {
        h2 {
          color:white;
          font-size:16px;
          line-height:14px;
          i {
            margin-left:8px;
          }
        }
      }
    }
    @media only screen and (max-width : 59.9375em) {
      table th {
        display : none;
      }
      tr {
        td:not(.show-for-phablet-td):nth-child(n+4) {
          h3 {
            display:none;
          }
        }
        td:nth-child(n+9) {
          h3 {
            display:none;
          }
        }
      }
    }
    .payBtn {
      font-size: 16px;
      padding: 10px;
    }
  }
}
