.hspc-attach-file {

  .upload-image {
    border     : 1px solid red;
    margin-top : 20px;
    align-items: center;


  }



  .attach-photo-area {
    padding-top   : 10px;
    padding-right : 0;

    button,
    .button {
      margin-right : 0;
    }

    @include breakpoint(tablet) {
      button,
      .button {
        margin-right : 10px;
      }
    }
  ;
  }
}