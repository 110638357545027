.hspc-share-records {
  &.page-content{
    @media only screen and (min-width: 90em) {
      padding-right:0;
    }
  }
  @include breakpoint(medium) {
    #select-records-container {
      padding-left  : 30px;
      padding-right : 30px;

      overflow-y: visible;
      & div {
        overflow-y: visible;
      }
    }
  }

  .record-type {
    margin-left : 0.8rem;
  }

  .date-container {
    padding-top     : 20px;
    padding-bottom  : 10px;
    justify-content : flex-start;
    flex-wrap       : wrap;
    min-height      : 100px;

    > .grid-content {
      overflow-y: visible;
    }

    input {
      margin-bottom: 0px;
      &[type='date'] {
        min-width: 12rem;
      }

      // for RTL
      .moment-picker.right .moment-picker-container {
        right: 0px;
      }
    }
  }

  @include breakpoint(phablet down) {
    .date-container {
      border-bottom : none;
    }
  }

  @include breakpoint(phablet up) {
    .date-item {
      flex-basis: 100%;
    }
  }

  .subject-area {
    min-height : rem-calc(14) !important;
  }

  .sr-title-prefix {
    padding-right : rem-calc(10);
  }

  .hsp-content-debug img {
    display : none;
  }

  .spin-box {
    margin : auto 5px auto 0;
  }

  .header {
    order : 0;
  }

  .content {
    margin-top : rem-calc(20);
    order      : 1;

    .contentBox {
      margin-bottom: 50px;
      height: 23em;
      width: 26em;
      margin-right: 30px;
      border-radius: 20px;
      background-color: $neutral-two;
      padding:20px;
      display:inline-flex;
      flex-direction:column;
      box-shadow: 5px 5px 1px gainsboro;

      > * {
        display:block;
      }

      .upperContentBlock {
        min-height:100px;
      }

      @media only screen and (max-width:496px){
        height:auto;
        width:90%;
        &.last {
          margin-bottom:150px;
        }
      }

      hr {
        border-color:darkgrey;
      }

      &.selected {
        background-color:$neutral-three;
        box-shadow: 10px 10px 2px $subtext-color;
      }

      .dateBlock, .formatBlock {
        display:inline-block;
        width:180px;

        /* Dropdown button on hover & focus */
        .dropbtn:hover, .dropbtn:focus {
          background-color: gainsboro;
        }

        /* The container <div> - needed to position the dropdown content */
        .dropdown {
          position: relative;
          display: inline-block;
        }

        /* Dropdown Content (Hidden by Default) */
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: $neutral-three;
          min-width: 175px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          z-index: 1;
          padding: 20px;
          top: 20px;
          @include breakpoint(medium down) {
            @media only screen {
              right: 0;
            }
          }
          &.show {
            display:block;
          }
          input {
            margin-top:5px;
            margin-bottom:10px;
          }
          .datepicker-container {
            isc-datepicker {
              input {
                border: 1.5px solid lighten( $gray-vdark, 10% );
              }
            }
          }
          .visuallyhidden {
            border: 0;
      		  clip: rect(0 0 0 0);
      		  height: 1px;
      		  margin: -1px;
      		  overflow: hidden;
      		  padding: 0;
      		  position: absolute;
      		  width: 1px;
          }
        }



        h2{
          display:inline-block;
        }
      }

      .container {
        text-align : left;
      }

      .dateLabel {
        width:4.5em;
        display:inline-block
      }

      .formatLabel {
        width:3em;
        display:inline-block
      }

      .button {
        box-shadow: 2px 2px 1px darken(gainsboro, 20%);
        padding:0.5rem;
        border-radius:15px;
      }

      .buttonDivContainer {
        width:100%;
        text-align:center;
        .applyDiv {
          button {
            max-width:175px;
            padding:20px;
            margin-top:30px;
          }
        }
        .buttonDiv {
          @media only screen and (max-width:496px){
            display:none;
          }
          .actionBtn {
            margin-top:20px;
            border-radius:10px;
            width:100px;
            height:100px;
            h3 {
              color: white;
              i {
                font-size:40px;
              }
            }
          }
        }
        .backBtnContainer {
          text-align:left;
          button{
            padding:10px;
          }
        }
        #buttonDiv2 {
          @media only screen and (max-width:496px){
            display:inline-block;
            width:100%;
          }

          .actionBtnContainer {
            width:100%;
            text-align:center;
            i {
              font-size:36px;
            }
          }
          display:none;
        }
      }
    }

    .instructions {
      margin : rem-calc(20) 0;
    }
  }

  form {
    margin  : 0 0 20px 0;

    padding : 0;

    label {
      font-weight : $font-weight-bold;
      line-height : 1.3;
    }

    input {
      margin-bottom : rem-calc(5);
    }

    .grid-content {
      margin-bottom : rem-calc(20);
    }
  }

  .cancelBtnContainer{
    button {
      width:25%;
    }
  }


  .sr-workflow-buttons {
    @extend .workflow-buttons;
    // We had to override some default button styles here
    .section {
      @include breakpoint(phone) {
        flex-direction : column;

        .button {
          margin-bottom : 0;

          &:first-child {
            margin-bottom : rem-calc(10);
          }
        }

      }

      @include breakpoint(phablet) {
        flex-direction : row;

        .button {
          border-radius : 0.125rem;

          &:first-child {
            margin-bottom : 0;
          }
        }
      }
    }
  }

  .sr-row {
    padding : rem-calc(10) rem-calc(10);
  }

  @include breakpoint(phablet down) {
    .sr-row {
      border-bottom : $panel-border;
    }

    .content {
      border-top : 1px solid gainsboro;
    }
  }

}
