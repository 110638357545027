.hspc-app {
  background : white;

  form {
    border: none;
    padding-left: 0;
  }

  .nav-and-page{
    flex-flow: column nowrap;

    @include breakpoint(tablet){
      flex-flow: row nowrap
    }
  }

  .hiddenLink {
    color: inherit;
  }

  .page-content {

    @include breakpoint(phone){
      flex: 1 1 auto;
    }

    @include breakpoint(tablet){
      flex: 1 1 0;
    }

    @include breakpoint(xlarge){
      padding-#{$global-right}: 15%;
      padding-#{$global-left}: 0;
      @include bidirectional{
        padding-left: 15%;
        padding-right: 0;
      }
    }

    @include breakpoint (tablet down) {
      padding-left: 0;
    }
  }

  .hsp-content-debug {
    background : #cccccc;
    border     : $app-border-color;
    flex       : 0 0 auto;
  }
}

.app-content {

  flex-wrap : nowrap;

  .main-content {
    padding       : rem-calc(25) rem-calc(25) 0 rem-calc(25);
    margin-bottom : 1rem;

    @include breakpoint(tablet) {
      @include grid-orient(horizontal);
      flex      : 1 1 auto;
      flex-wrap : nowrap;
    }

    @include breakpoint(tablet down) {
      @include grid-block($size : shrink, $orientation : vertical);
      flex : 1 0 auto;
    }

  }
}

// To make the (readonly) inputs look like active inputs with text cursor
.mock-active-input-text {
  background-color : #ffffff;
  cursor           : text;
}

// To make the (readonly) inputs look like active inputs with pointer cursor
.mock-active-input-pointer {
  background-color : #ffffff;
  cursor           : pointer;
}

// uifw tweaks
.menu-bar {
  li {
    margin-bottom : 0;
  }
}
