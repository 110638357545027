.hspc-upload-documents {
  #addDocBlock {
    margin: 0 auto;
    border-radius:20px;
    padding:20px;
    border:1px solid gainsboro;
    background-color:$neutral-three

  }
  #headerContainer {
    padding-right : 15px;
    flex-shrink: 0;
    #uploadDocBtn {
      display:inline-block;
    }
    .inline-label {
      margin-left : 25px;
      float       : left;
    }
    #searchInput {
      max-width : 200px;
      min-width : 100px;
      float     : left;
    }
    .resetFilterBtn {
      min-width:250px;
      max-width:40%;
      display:inline-block;
    }

    #filterDiv {
      .filter {
        display:inline-block;
        vertical-align:top;
        margin-right     : 30px;
        background-color : $neutral-three;
        padding          : 5px;
        margin-bottom    : 20px;
        max-width        : 50%;
        min-width        : 250px;
        h3 {
          min-width : 100px;
        }
        span {
          width:15em;
          display:inline-block;
          margin-right : 10px;
          label {
            min-width   : 100px;
          }
        }
      }
    }
  }

  .stepHeaders {
    margin-bottom:20px;
    &.pull-up {
      margin-top:-25px;
      margin-top:-25px;
    }
  }

  .addNewDocument {
    .hidden {
      display : none;
    }
    overflow-y: visible;
    padding-right: 25px;
    h2 {
      padding-bottom : 20px;
    }
    #buttonDivContainer {
      margin-top:20px;
      width:100%;
      border-top:1px solid gainsboro;
      #buttonDiv {
        margin-top:20px;
        float:right;
      }

    }
    #fullContainer {
      margin-left : 30px;
      .hidden {
        display : none;
      }
      #selectionContainer {
        border-radius    : 10px;
        background-color:$neutral-three;
        padding   : 50px 40px 20px 40px;
        width     : 40%;
        min-width : 275px;
        margin-top: 30px;
        max-width : 550px;
        @media only screen and (min-width : 2000px) {
          margin-right:350px;
        }
        @media only screen and (min-width : 1500px) and (max-width : 2000px){
          margin-right:50px;
        }
      }
      #fileSelectionDiv {
        background-color : lighten( $gray-dark, 10%);
        border-radius    : 10px;
        border-style     : dashed;
        border-color     : lighten( $gray-vdark, 10%);
        border-width     : 2.5px;
        height           : auto;
        min-height       : 250px;
        text-align       : center;
        align-items      : center;
        margin           : 0 auto;
        min-width        : 175px;

        span {
          color: $primary-color;
        }

        .hspc-attach-file {
          #fileAttachLabel {
            display: none;
          }
          margin : 0 auto;
          width  : rem-calc(175px);
        }

        .hspc-file-upload, .hspc-attach-photo {
          margin : 0 auto;
          .hidden-file-label, .grid-block {
            margin     : 0 auto;
            overflow-y : hidden;
          }
          .attachments {
            padding : 20px 0px 20px 0px;
            width:90%;
            .hspc-attachment {
              min-height:70px;
              label {
                display   : block;
                word-wrap : break-word;
                width         : 70%;
              }
            }
            .delete-button {
              background-color : $primary-color;
              color            : #fff;
              margin-right     : 10px;
            }
          }
        }
      }
      .selectionArrow {
        font-size : 100px;
        padding   : 25px;
        &.fa-check-circle {
          color : $success-color;
        }
      }

      #selectionText {
        padding-top : 20px;
        text-align  : center;
      }

      #uploadDocForm {
        border-radius    : 10px;
        min-width:275px;
        background-color:$neutral-three;
        padding-left: 30px;
        width : 45%;
        display:inline-block;
        margin-top:30px;
        margin-right:25px;
        float:left;
        max-width:550px;
        form{
          padding-top:30px;
          padding-bottom:0;
        }
        select {
          margin-bottom : 50px;
          width:90%;
        }
        textarea {
          height : 75px;
          width:90%;
          margin-bottom:70px;
        }
      }

      .grey {
        font-size : 10px;
        color     : darken( $gray-vdark, 15% );
      }
    }

    @media screen and (max-width : 655px) {
      #fullContainer {
        margin-left:0px;
        #selectionContainer, #uploadDocForm {
          float : left;
          width : 100%;
        }
      }
    }
  }

  .downloadBtn {
    display : inline-block;
  }
  .uploadDropdown {
    display : inline-block;
    width   : 30%
  }

  .documentTable {
    table {
      margin-right : 15px;
      th {
        padding-left : 5px;
      }

      thead {
        tr {
          :nth-last-child(2) {
            display:none;
          }
        }
      }

      tbody {
        tr {
          background-color : $neutral-three;
          &.inactive {
            background-color : transparentize($gray, 0.45);
          }
          td:last-child {
            min-width : 130px;
          }
          td {
            max-width:450px;
            padding : 0.5rem 0.3rem 0.5rem 5px;
            @media only screen and (max-width : 59.9375rem) and (min-width : 40em) {
              h3 {
                font-size   : 0.8125rem;
                font-weight : 700;
                color       : $primary-color;
              }
            }
          }
        }
      }
    }
  }

  p {
    margin : 10px 0 10px;
  }

  .btn-flex {
    display       : flex;
    align-items   : stretch;
    align-content : stretch;
  }

  .btn-flex .btn:first-child {
    flex-grow  : 1;
    text-align : left;
  }

  .x-split-button {
    position   : relative;
    display    : inline-block;
    text-align : left;
    &.hide-for-tablet:not(.ng-hide) {
      @media only screen and (max-width: 60em) {
        display: inline-block !important;
      }
    }
  }

  .x-button {
    position    : relative;
    margin      : 0;
    height      : 30px;
    float       : left;
    outline     : none;
    line-height : 15px;
    border      : 1px solid $neutral-color;
    box-shadow  : 1px 1px 2px $neutral-color;
  }

  .x-button:hover {
    cursor     : pointer;
    background : darken( $primary-color, 10% );
  }

  .x-button.x-button-drop {
    border-left : 0;
    height      : 30px;
    .fa-caret-down {
      font-size : 20px;
    }
  }

  .open > .x-button-drop-menu {
    display : block;
  }

  .x-button-drop-menu {
    position         : absolute;
    top              : 27px;
    right            : -125px;
    z-index          : 1000;
    display          : none;
    float            : left;
    min-width        : 160px;
    padding          : 5px 0;
    margin           : 2px 0 0;
    font-size        : 14px;
    list-style       : none;
    background-color : $neutral-color;
    background-clip  : padding-box;
    border           : 1px solid $gray-dark;
    box-shadow       : 1px 1px 2px $gray-dark;
    li {
      a {
        display         : block;
        padding         : 3px 20px;
        clear           : both;
        font-family     : arial;
        color           : #444;
        text-decoration : none;
      }
      .fa {
        margin-right : 10px;
      }
    }
  }

  .x-button-drop-menu li a:hover {
    background-color : rgba(201, 191, 212, 1)
  }

  a.disabled {
    color : #888;
  }

  @media only screen and (max-width : 59.9375em) {
    .documentTable table th {
      display : none;
    }
    .splitBtnContainer {
      display : none;
    }
    .x-button-drop-menu {
      right : auto;
    }
  }

  @media screen and (max-width : 640px) {
    #headerContainer {
      .inline-label {
        margin-left   : 0;
        margin-right  : 20px;
        margin-bottom : 1rem;
      }
      #showActiveContainer {
        float         : left;
        margin-bottom : 5px;
      }
    }
  }
}
