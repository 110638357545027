.search-results-display {
  padding-top : rem-calc(20);
  font-size   : rem-calc(14);

  .item-section {
    border-top  : 1px solid $app-border-color;
    padding-top : rem-calc(20);
    margin      : rem-calc(10) 0;
  }

  .row-block {
    .radio-block {
      padding-left : rem-calc(5);
      padding-top  : 2px;
    }

    span {
      padding : 0 rem-calc(5) rem-calc(5) 0;
    }
  }

  .provider-mobile {
    border-top  : 1px solid $app-border-color;
    margin-top  : rem-calc(10);
    padding-top : rem-calc(5);

    span {
      padding : 0 rem-calc(5) rem-calc(5) 0;
    }

    .list-items {
      margin-top : rem-calc(10);

      span:not(:last-child)::after {
        content : ',';
      }
    }
  }

  .main {
    font-weight : 600;
    color       : $primary-color;
  }

  .add-remove-button {
    max-height : rem-calc(50);

    @include breakpoint (phone only) {
      margin-top : rem-calc(10);
    }
  }

  input[type="radio"] {
    margin-top : 0.5px;
  }
}
