.brand-nav {

  padding: rem-calc(15);

  background: $top-nav-background;

  background-size: cover;

  background-position: top left;



  .logo{

    background-image: $logo;

    background-repeat: no-repeat;

    background-position: 1.5% center;

    width: 200px;

    height: 50px;



    @include breakpoint(tablet up){

      background-size: rem-calc(140);

    }



    @include breakpoint(phablet only){

      background-size: rem-calc(110);

    }



    @include breakpoint(phablet down){

      background: none;

    }

  }



  .lhac-header{

    color: $logo-color;

    //position: fixed;

    //margin-left: 250px;



    @include breakpoint(tablet down){

      display: none;

    }

  }

}



