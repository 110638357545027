$hspc-nav-padding : rem-calc(5) rem-calc(15) rem-calc(10) 0;

.hspc-side-nav {

  border                 : none;

  margin-#{$global-right}: rem-calc(20);
  @include bidirectional {
    margin-left : rem-calc(20);
  }

  margin-#{$global-left} : rem-calc(0);
  @include bidirectional {
    margin-right : rem-calc(0);
  }

  margin-bottom          : 0;

  @include breakpoint(tablet) {
    flex                   : 0 0 auto;
    min-width              : rem-calc(220px);
    max-width              : rem-calc(320px);
    padding                : 0;
    box-sizing             : border-box;
    border                 : 1px solid $app-border-color;
    @include breakpoint(tablet down) {
      border : none;
    }
    margin-#{$global-left} : rem-calc(10);
    @include bidirectional {
      margin-right : rem-calc(10);
    }
  }

  .section-header {
    padding-top           : rem-calc(10);
    margin-bottom         : rem-calc(5);

    margin-#{$global-left}: rem-calc(10);
    @include bidirectional {
      margin-right : rem-calc(10);
    }
  }

  .side-panel {
    flex       : 1 1 auto;
    background : $secondary-color;

    @include breakpoint(tablet down) {
      padding-top : rem-calc(40);
    }
  }

  .dropdown {
    font-size  : rem-calc(25);

    transition : color 0.2s ease;
    color      : $primary-color;

    &:hover, &:focus {
      color : lighten($primary-color, 20%);
    }
  }

  .chevron::before {
    border-style   : solid;
    border-width   : 0.15rem 0.15rem 0 0;
    content        : '';
    display        : inline-block;
    width          : 1rem;
    height         : 1rem;

    $global-left : 0.15rem;
    @include bidirectional {
      right : 0.15rem;
    }

    position       : relative;
    top            : 0.5rem;
    transform      : rotate(-45deg);
    vertical-align : top;
  }

  .chevron.bottom:before {
    top       : 0.1rem;

    $global-left : 0.5rem;
    @include bidirectional {
      right : 0.5rem;
    }

    transform : rotate(135deg);
  }

  @import "../../assets/sass/hspc/hspc-tabs";

}
