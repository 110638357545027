.hr-panel {
  @include breakpoint (tablet){
    flex: 0 0 28%;
  }

  @include breakpoint (desktop) {
    flex: 0 0 22%;
  }
}

.hr-content {
  flex: 1 0 70%;
}

.grey {
  color: $subtext-color;
}

#time-cat-nav {

  &.panel {
    @include breakpoint(tablet) {
      max-width: 260px;
    }

    @media only screen and (min-width: 960px) and (max-width: 1150px){
      margin-right: rem-calc(10);
      min-width: 260px;
    }
  }
}

.hspc-timeline {
  order: 0;
  margin: 0 !important;

  @include breakpoint(tablet) {
    margin: rem-calc(0);
  }

  .header {
    @include timeline-width();
    //width: 100%;
    color       : $primary-color;
    align-items : stretch;

    .control-block {
      @include timeline-width();
      padding: rem-calc(10);
      justify-content: space-around;
      border: $panel-border;
      border-bottom: none;
      order: 1;

      .date-range{
        font-size: 12px;
        span{
          padding-bottom: 5px;
        }
      }

      @include breakpoint( tablet down ){
        width: 100%;
      }

      select{
        width: 50%;
        margin-bottom: 0;
      }
    }

    .close-btn-group{
      width : 100%;
      align-items: center;
      justify-content: space-between;

      h2 {
        order       : 1;
        margin: rem-calc(10) rem-calc(20);
      }

      .close-button-2 {
        order       : 2;
        font-size   : 36px;
        font-weight : bold;
        margin: 0 10px;

        &:hover {
          color : darken(white, 15);
        }
      }
    }
  }

  .scroll-container {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 0;

    @include breakpoint(tablet) {
      width : $timeline-width;

      // limit width of datepickers due to scrollbar
      hspc-moment-picker {
        & input {
          min-width     : auto;
          width         : $timeline-width-value - 40px;
          margin-bottom : rem-calc(10);
        }
        & .moment-picker-container {
          min-width : auto;
          width     : $timeline-width-value - 25px;
        }
      }
    }
  }

  .tab-container {
    @include timeline-width();
    background: $secondary-color;
    border: $panel-border;

    &.category {

      .tab-item {
        &.is-active {
          outline: rem-calc(1) solid $app-border-color;
          color: darken($gray-vdark, 15%);
          background: white;
        }
        &:not(.is-active){
          outline: rem-calc(1) solid transparent;
          color: $primary-color;
        }
        .circle {
          background-color: $app-border-color;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;

          &.new {
            background-color: $primary-color;
          }
        }

        .color-bar{
          margin-#{$global-right}: 5px;
          @include bidirectional{
            margin-left: 5px;
          }
        }
      }
    }

    &.timeline {
      overflow: visible;
      padding-top: 0;

      .date-block {
        background: $secondary-color;
        border-top: $panel-border;

        .data-column {
          min-height: 5px;
          padding: 0;
          margin: 5px 0;
        }
      }

      .date-column {
        padding: rem-calc(10);
        color: $primary-color;
        font-weight: 100;
        font-size: rem-calc(14);

        .year {
          color: black;;
        }
      }

      .data-column {
        padding: rem-calc(15);
        min-height: $row-min-height;

        .color-bar-container {
          flex-flow: row wrap;
          margin-bottom: rem-calc(5);
        }

        .visit-info {
          margin-bottom: rem-calc(10);
          white-space: normal;

          &:last-child {
            margin-bottom: 0;
          }

          .grey-vdark {
            color: darken($gray-vdark, 10%);
          }

          span {
            display: block;
            font-weight: 200;
            font-size: rem-calc(14);
            padding-bottom: 5px;

            &:first-child {
              font-weight: 400;
            }
          }
        }
      }

      .tab-item {
        margin: 0;
        padding: 0;

        &.empty {
          cursor: auto;
          > div {
            margin: rem-calc(15) 0;
            &:hover {

            }
          }
        }
      }
    }
  }
}
