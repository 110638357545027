.tab-nav {
  overflow : visible;
  height   : rem-calc(40);

  .tab-item {
    outline         : none;
    height          : rem-calc(45);
    margin-top      : rem-calc(-5);
    background      : transparent;
    padding         : 1rem;

    cursor          : hand;
    cursor          : pointer;

    font-weight     : normal;
    font-size       : rem-calc(16);
    color           : $primary-color;
    text-align      : center;
    text-decoration : none;
    line-height     : rem-calc(20);

    &.active, &.active-tab {
      color                   : black;
      background              : white;
      border                  : 1px solid $app-border-color;
      border-bottom           : 1px solid white;
      border-top-left-radius  : $global-radius;
      border-top-right-radius : $global-radius;
      z-index                 : 50;
      cursor                  : default;
    }

    &:focus {
      // Use the same background color as the nav-bar
      background              : $secondary-color;
      border                  : 1px solid $app-border-color;
      border-bottom           : 1px solid white;
      border-top-left-radius  : $global-radius;
      border-top-right-radius : $global-radius;
      color                   : black;
    }
    &:hover:not(.active) {
      color : black;
    }
  }
}

.date-container {
  padding       : 0 10px;
  border        : none;
  overflow      : visible;
  border-bottom : $panel-border;

  label {
    margin-bottom : 0;
  }

  datepicker, isc-datepicker {
    input[type='text'] {
      margin-bottom : 10px;
      text-align    : left;
    }

    .moment-picker-container {
      left : 0px;
    }
  }
}

.tab-container {
  background : $secondary-color;
  padding    : rem-calc(10) 0;

  @include breakpoint(tablet down) {
    outline : none;
  }

  hr {
    margin  : 10px 0;
    padding : 0;
  }

  .tab-item {
    outline         : rem-calc(1) solid transparent;
    box-sizing      : border-box;
    margin          : 0 rem-calc(10);
    padding         : rem-calc(10);
    font-weight     : normal;
    font-size       : rem-calc(16);
    line-height     : rem-calc(16);
    color           : $primary-color;
    text-decoration : none;

    cursor          : pointer;

    &.is-active {
      outline     : rem-calc(1) solid $app-border-color;
      color       : black;
      font-weight : normal;
      background  : white;
      z-index     : 100;
    }

    &:focus,
    &:active,
    &:hover:not(.active):not(:active) {
      outline     : rem-calc(1) solid $app-border-color;
      color       : darken($gray-vdark, 5%);
      font-weight : bold;
      background  : white;
      z-index     : 90;
    }

    .arrow-next {
      margin-#{$global-right}: rem-calc(5);
      @include bidirectional {
        margin-left : rem-calc(5);
      }
    }
    .tab-title {
      width : 80%;
    }

    .read-message-count {
      width      : 20%;
      text-align : $global-right;
      @include bidirectional {
        text-align : left;
      }
    }

    label {
      margin-bottom : 0;
      cursor        : pointer;
    }

    input[type='checkbox'] {
      margin-top : 0;
    }
  }
  // end tab-item
}

//end tab-container
