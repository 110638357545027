.login-items {



  form {

    border        : none;

    padding-left  : 0;

    padding-right : 0;



    @include breakpoint(tablet) {

      padding-#{$global-right}: rem-calc(16);

      @include bidirectional() {

        padding-left : rem-calc(16);

      }

    }



    .info {

      padding-bottom: 1.25rem;

      color: $light-text;

    }

  }



  .button,

  button {

    width : 100%;

  }



  .login-field {

    margin-bottom : rem-calc(20);

    border-radius : rem-calc(3);

  }



  .enroll {

    margin-top : rem-calc(10);



    h3 {

      color         : white;

      margin-bottom : 1rem;

    }

  }



  .devices {

    padding-bottom : rem-calc(15);



    label {

      color : white;

      input[type='radio'] {

        margin-top : 0

      }

    }

  }



  #forgot {

    padding-top : rem-calc(15);

  }



  #activate {

    @media only screen and (min-width : rem-calc(639)) {

      float : right;

    }

    @media only screen and (max-width : rem-calc(639)) {

      display     : block;

      padding-top : rem-calc(10);

    }

  }



  .login-btn {

    color         : white;

    font-size     : 20px;

    margin-bottom : 1rem;

  }



  .login-title {

    h1 {

      color         : white;

      margin-bottom : 1rem;

    }

  }

}



.saml-login {

  .error {

    color         : $alert-color;

    display       : inline-block;

    margin-bottom : 0;

    margin-top    : 1rem;

  }



  .no-flex {

    flex : none;

  }



  .align-center {

    text-align : center;

  }



  .logo-div {

    background    : $top-nav-background;

    margin-bottom : 1rem;

    padding       : 0.5rem;



    .logo {

      background-image  : $logo;

      height            : 100px;

      width             : 350px;

      background-repeat : no-repeat;

    }

  }



  .request-info {



    span {

      line-height : 1.2rem;

    }



    h2 {

      margin : 0.25rem 0;

    }

  }



  .login {



    margin-bottom  : 0;

    padding-bottom : 0;



    @media #{$medium} {

      padding-top : 1.5rem;

    }



    @media #{$small} {

      padding-top : 1.5rem;

    }



    label {

      text-align  : right;

      line-height : 2.4rem;



      &::after {

        content : ':'

      }

    }



    input[name="password"] {

      margin-bottom : 1rem;

    }

  }



  .login-controls {



    .section {

      align-content : center;

      flex-wrap     : nowrap;



      &.main-actions {

        padding         : 0.5rem 1rem;

        justify-content : flex-end;

        @include breakpoint(tablet down) {

          .button {

            margin : 0;

            &:first-child {

              margin-bottom : 1rem;

            }

          }

        }

      }



      &.secondary-actions {

        justify-content : flex-end;

        padding-bottom  : 0.5rem;



        a {

          display : inline-block;



          &:first-child {

            margin-#{$global-right} : 3rem;

            margin-#{$global-left}  : 0;

            @include bidirectional {

              margin-right : 0;

              margin-left  : 3rem;

            }

          }

        }



        @include breakpoint(tablet down) {

          a {

            margin     : 0;

            text-align : center;



            &:first-child {

              margin        : 0;

              margin-bottom : 1rem;

            }

          }

        }

      }

    }



    .button {

      margin-bottom : 0;



      @include breakpoint(tablet) {

        &:first-child {

          margin-right : 1rem;

        }

      }



      @include breakpoint (tablet down) {

        width : 100%;

        &:last-child {

          margin-right : 0;

        }

      }

    }

  }

}

