// styles used in several places
[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

.clickable {
  cursor: pointer;
  cursor: hand;
  outline: none;
}

.unselectable {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.hide-for-phablet-td {
  @include hide-for(phablet, table-cell);
}

.show-for-phablet-td {
  @include show-for(phablet, table-cell);
}

.rtl{
  direction:rtl;
}

.hsp-content-debug {
  font-size: rem-calc(14);

  p {
    margin-bottom: 0;
  }

  ul {
    li {
      font-size: rem-calc(14);
    }
  }
}

.shrink {
  flex: 0 0 auto;
  overflow: visible;
}

[ng-bind-html] div {
  padding: .5rem;
  padding-left: 0;
  ul {
    margin-top: 1.1rem;
  }
}

/* FA Icons colors */

.fa {
  &.success {
    color: $success-color;
  }
  &.alert {
    color: $alert-color;
  }
  &.warning {
    color: $warning-color;
  }
}


input#edgeFix {
  position: absolute;
  top: -100px;
  width:1px;
  height:1px;
}

// title formatting

.formatted-title{
  @include grid-block();

  .title-suffix{
    padding: 0 0.5em;
    color: $dark-text;
  }

}

