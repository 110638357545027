.hspc-my-forms {
  .filter {
    width            : 280px;
    background-color : #f8f8f8;
    padding          : 10px;
    
    label {
      display      : inline-block;
      margin-right : 20px;
    }
  }
}
