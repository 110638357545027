.hspc-modal {

  .title-bar {
    text-transform : none;
  }

  .fileTypeList {
    li {
      margin-bottom : -0.3rem;
    }
  }

  aside {
    outline : none;
    @include grid-block();

    div.grid-block {
      // .grid-block will be flexbox and most browsers will ignore width: 100%,
      // but IE11 needs it or the content div ends up being 3x as wide as its aside container
      width : 100%;
    }
  }

  .title-bar {
    margin : 0;

    div.title {
      // In the case of a very long modal title, this will wrap it
      white-space : normal;
    }
  }

  .message-area {
    background : white;
    padding    : rem-calc(16);

    // Fixes issue of bottom line being cut off or producing a scrollbar
    div {
      line-height : normal;
    }

    .grid-content {
      padding-left : 0;
    }

    .calendar-date {
      font-size   : rem-calc(12);
      font-weight : 700;

      span {
        padding-right : rem-calc(5);
      }
    }

    .section {
      margin-top  : rem-calc(20);
      padding-top : rem-calc(7);

      &.grid-content {
        padding-left : 0;
      }
    }
  }

  .footer {
    border-top     : $panel-border;
    padding        : rem-calc(16);
    padding-bottom : 0;
  }

  // To make the (readonly) inputs look like active inputs with text cursor
  .mock-active-input-text {
    background-color : #ffffff;
    cursor           : text;
  }

  form {
    input:valid, input.ng-pristine {
      border-color : lighten($gray-vdark, 12%);
    }
  }
  // To line up the "Show Password" text with the checkbox
  #show-password {
    margin-top : 3px;
  }

  .idp-login-items .button {
    background-color : white;
    width            : 100%;

  }

  .idp-enroll-items-modal .button {
    background-color : white;
    width            : 100%;
  }

  .loginButton {
    text-align : center;
    width      : 100%;
    .button {
      width         : 40%;
      margin-bottom : 0;
    }
  }

   .idp-login-items, .idp-enroll-items-modal {
    .button span {
      display : inherit;
      svg {
        width  : 50%;
        height : 50%;
        @media only screen and (max-width : rem-calc(1200)) {
          height : 80%;
          width  : 80%;
        }
        @media only screen and (max-width : rem-calc(639)) {
          height : 100%;
          width  : 100%;
        }
      }
    }
  }
  .idp-login-items, .idp-enroll-items-modal{
    .button {
      .IEBrowser {
        display:inline;
      }
    }
  }

  .idp-login-items {
    .onlyIdp span {
      display : inherit;
      float   : left;
      svg {
        float  : left;
        height : 80%;
        width  : 80%;
        @media only screen and (max-width : rem-calc(1200)) {
          height : 90%;
          width  : 90%;
        }
        @media only screen and (max-width : rem-calc(639)) {
          height : 100%;
          width  : 100%;
        }
      }
    }
  }
  .idp-login-items{
    .onlyIdp {
      .IEBrowser {
        display:inline;
      }
    }
  }

  .onlyIdpEnroll span {
    display : inherit;
    svg {
      width  : 80%;
      height : 80%;
      @media only screen and (max-width : rem-calc(1200)) {
        height : 90%;
        width  : 90%;
      }
      @media only screen and (max-width : rem-calc(639)) {
        height : 100%;
        width  : 100%;
      }
    }
  }

  .idp-enroll-items-modal{
    .onlyIdpEnroll {
      .IEBrowser {
        display:inline;
      }
    }
  }

  .enroll {
    text-align : center;
    .hspcOAuthEnrollBtn {
      border-radius : rem-calc(4);
      height        : 71.8px;
      padding       : rem-calc(3) 0 rem-calc(3) 0;
      width         : 418px;
      svg {
        height : 90%;
        width  : 90%;
      }
      @media all and (max-width : 1200px) {
        height : 73.64px;
        width  : 428.8px;
      }
      @media all and (max-width : 639px) {
        height : 74.89px;
        width  : 436px;
      }
      @media all and (max-width : 500px) {
        height : 70%;
        width  : 88%;
      }
    }
  }

  .hspc-device-confirmation {
    padding-left : 1em;
    margin-top   : 1em;
    @media all and (max-width : 1200px) {
      margin-top : 0;
    }
  }

  .separator {
    display       : flex;
    color         : black;
    align-items   : center;
    text-align    : center;
    margin-bottom : 0.25rem;
  }

  .separator::before, .separator::after {
    content       : '';
    flex          : 1;
    border-bottom : 1px solid black;
  }

  .separator::before {
    margin-right : .25em;
  }

  .separator::after {
    margin-left : .25em;
  }

}
