//ARIA STUFF
//for some form controls, hiding a label from view but still having it "visible" to screen reader is the way
//to get reader to "read" value
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
