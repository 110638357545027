
.select-prescription {

  .sort-buttons{
    margin-top: rem-calc(20);
    font-size: rem-calc(14);
  }

  input[type=radio]{
    margin-left: rem-calc(5);
  }

  table {
    margin-top: rem-calc(10);
    // need to augment the regular table style because
    // there are several divs inside a <td> but only one of them is main
    .main {
      color: $primary-color;
      font-weight: 700;
      padding-bottom: rem-calc(5);
    }

    tbody{
      tr{
        vertical-align: top;
        td{
          div{
            &:not(:first-child){
              //margin-top: rem-calc(10);
            }
          }
        }
      }
    }

    &.mobile {
      tbody {
        border-top: 1px solid $app-border-color;
        tr {
          td {
            border: none;
          }

        }
      }
    }
  }

}
