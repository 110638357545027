form.formly {
  // Add any SASS or CSS that should apply to online forms and questionnaires here.
  // You can define classes here, and you can also apply styles to all occurrences of
  // specific field types.

  // N.B.: Any styles that are not inside this form.formly block
  // will apply across the entire application, not just to forms!

  // This example style changes the font size of text boxes in a form:
  // input[type='text'] {
  //   font-size: 1.2rem;
  // }
  overflow-y: scroll;
}
