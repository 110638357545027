.hspc-search {

  .hspc-back-button {
    @include button(medium, false, $secondary-color,
      scale-color($secondary-color, $lightness: -15%), auto);
    margin: 0;
    min-height : 45px;
    transform: scaleX(-1);

    svg {
      padding: 0;
      fill   : $primary-color;
      height : 20px;
      width  : 20px;
    }
  }

  .hspc-health-search-bar {
    width: 100%;
    max-width: 700px;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(30);

    input[type="search"] {
      margin-bottom: rem-calc(20);
      font-size: $subheader-font-size;
    }

    input[type="submit"] {
      font-size: $subheader-font-size;
    }

    input:required {
      box-shadow: none;
    }
  }

  .health-warning{
    margin-bottom: rem-calc(20);
    padding-right: 2rem;
  }

  .search-results {
    margin-top: rem-calc(20);

    .search-item {
      border-top: 1px solid $app-border-color;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      margin-top: rem-calc(20);
      padding-top: rem-calc(10);
      padding-right: 2rem;

      .content{

        p{
          margin-top: rem-calc(10);
        }

        ul {
          margin-top: rem-calc(10);
          margin-left: rem-calc(30);
        }
      }

      .view-more-link{
        line-height: 300%;
      }
    }

    .health-warning {
      color: $body-font-color;
      font-style: italic;
    }
  }

  img.HwHcMode,
  .HwHighContrastMode img.HwNoHcMode {
    display: none;
  }

  img.HwNoHcMode,
  .HwHighContrastMode img.HwHcMode {
    display: block;
  }
}
