.info {

  .grid-container {
    max-width: none;
    margin: 0;
  }

  form {
    max-width: 500px;

    input[readonly] {
      background-color : #fff;
      cursor           : text;
    }
  }

  select {
    margin-top: rem-calc(0.5);
  }

  .info-form {
    border: none!important;
    overflow: hidden;
  }

  .content {
    margin-bottom: rem-calc(30);
  }

  .text-info {
    margin: rem-calc(20) 0;
    // padding-bottom: rem-calc(10);

    &.grid-content {
      padding: 0;
    }
  }

  .form-section {
    padding-top: rem-calc(15);

    &:first-child{
      padding-top: 0;
    }
  }

  .preferences-section {
    .inline {
      display: inline-block;
    }
  }

  .languages-section {
    padding-top:20px;
  }

  .form-label {
    font-size: rem-calc(16);
    margin-bottom: rem-calc(20);
  }

  .instructions {
    margin: 0 0 rem-calc(20) 0;
    font-style: italic;
  }

  .dob {
    padding-bottom: rem-calc(40);
  }

  .mmn {
    padding-top: rem-calc(10);
    margin-bottom: rem-calc(10);
  }

  .ssn {
    margin-bottom: rem-calc(5);
  }

  .fpw-radio-row {
    padding-left: rem-calc(5);
    margin-bottom: rem-calc(10);
    flex: 0 0 auto;
    line-height: 2;
  }

  .pw-info {
    margin-top: rem-calc(20);
  }

  .response-message {
    color: $alert-color;
  }

  .reset-pw-message {
    @extend .response-message;

    margin-top: rem-calc(10);
    margin-bottom: rem-calc(20);
  }

  .help-text{


    div {
      padding-bottom: rem-calc(20);

      ul {
        list-style-position: inside;

        li {

          margin: dir-values(5px 0 0 15px);

          @include bidirectional() {
            margin: flip-dir(5px 0 0 25px);
          }
        }
      }

    }
  }

  .help-params {
    font-size: rem-calc(10)
  }

  .ip-workflow-buttons {
    @extend .workflow-buttons;

    // We had to override some default button styles here
    .section {
      @include breakpoint(phone) {
        flex-direction: column;

        .button {
          margin-bottom: 0;

          &:first-child {
            margin-bottom: rem-calc(10);
          }
        }

      }

      @include breakpoint(phablet){
        flex-direction: row;

         .button {
          &:first-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
