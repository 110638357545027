/* 404 Error Page Template */
@include exports(404error) {

  .error-page-container {
    text-align: center;
    margin-top: 6rem;
    font-size: 1.5rem;
    padding: 1rem;

    .error-title {
      font-size: 5.5rem;
      @include breakpoint(tablet){
        font-size: 7rem;
      }
    }

    .error-subtitle {
      font-size:1.75rem;
    }

    .seperator {
      margin-top:3rem;
    }
  }
}
