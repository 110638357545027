@mixin timeline-width() {
  @include breakpoint(tablet) {
    width : $timeline-width;
  }
}


.healthRecords {

  .grid-container {
    max-width  : none;
    margin     : 0 0;
    overflow-y : auto;
  }

  .no-wrap {
    flex-wrap: nowrap;
  }

  .time-cat-buttons {
    button {
      margin-right : 0;

      @include breakpoint (tablet down) {
        margin-bottom : 1rem;
      }
    }
  }

  #shareRecordLinks {
    width:200px;
    @media screen and (max-width:425px) {
      width:100%;
      text-align:center;
    }
    i {
      display:inline-block;
      font-size:125%;
    }
  }

  @import "timeline/hspc-timeline";
  @import "timeline/directives/hspc-filters";

  @import "workflows/hspc-wellness-workflows";

  @import "directives/chart/hspc-wellness-chart";

  @import "directives/workflowDetails/hspc-wellness-details";
  @import "directives/filterBar/hspc-wellness-filter-bar";

  .hspc-side-nav {
    border                 : none;
    padding-top: 5px;
    padding-right: 5px;

    input[type='checkbox'] {
      margin-bottom : 0;
    }
    label {
      white-space : normal;
    }
  }
}

// Mobile view for filters (slide-in panel)
.panel-fixed {
  @import "timeline/directives/hspc-filters";
}
