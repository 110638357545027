.hspc-date-picker{
  padding: 0 0 20px 0;

  .hspc-form-error-message{
    clear: both;
  }

  label{
    font-weight: 400;
    padding: 0 5px;
    margin-bottom: 0;
    width: 100%;
  }
  
}