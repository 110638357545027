/* Tooltip Settings */
$tooltip-background: $primary-color !default;
$tooltip-border-color: smartscale($tooltip-background, 10%) !default;
$tooltip-border: 1px solid $tooltip-border-color !default;
$tooltip-font-size: 0.875rem !default;
$tooltip-radius: $global-radius !default;
$tooltip-line-height: $paragraph-line-height !default;
$tooltip-padding: 0.5rem 0.625rem !default;
$tooltip-max-width: 25% !default;

/* Tooltip Styles */
@include exports(tooltip) {
	.tooltipster-sidetip .tooltipster-box {
		border-radius: $tooltip-radius;
		border: $tooltip-border;
		background: $tooltip-background;
		color: isitlight($tooltip-background, $body-font-color, $light-text);
	}

	.tooltipster-sidetip {
		&.tooltipster-bottom {
			.tooltipster-arrow-background {border-bottom-color: $tooltip-background;}
			.tooltipster-arrow-border {border-bottom-color: $tooltip-border-color;}
		}

		&.tooltipster-left {
			.tooltipster-arrow-background {border-left-color: $tooltip-background;}
			.tooltipster-arrow-border {border-left-color: $tooltip-border-color;}
		}

		&.tooltipster-right {
			.tooltipster-arrow-background {border-right-color: $tooltip-background;}
			.tooltipster-arrow-border {border-right-color: $tooltip-border-color;}
		}

		&.tooltipster-top {
			.tooltipster-arrow-background {border-top-color: $tooltip-background;}
			.tooltipster-arrow-border {border-top-color: $tooltip-border-color;}
		}
	}

	.tooltipster-content {
		font-family: $body-font-family;
		font-size: $tooltip-font-size;
		line-height: $tooltip-line-height;
		padding: $tooltip-padding;
		overflow: hidden;
	}

	/* Icons */
	.tooltipster-icon {
		cursor: help;
		margin-#{$global-left}: 0.25rem;

		@include bidirectional() {
			margin-right: 0.25rem;
		}
	}
}
