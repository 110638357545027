$orange-text : #D86D40;

.library {

  .page-content {
    -ms-overflow-style : scrollbar;
  }

  p {
    margin-bottom : 0;
  }

  .grid-container {
    max-width : none;
    margin    : 0;
  }

  .header {
    margin-bottom : rem-calc(10);
  }

  .header2 {
    margin-top    : rem-calc(20);
    margin-bottom : rem-calc(10);
  }

  .news-section {
    margin-bottom : rem-calc(10);

    .news-item-title {
      padding    : rem-calc(5);
      background : $gray-vdark;
      p {
        color       : white;
        font-weight : 600;
      }
    }

    .news-item-section {
      padding-top   : rem-calc(5);
      margin-top    : rem-calc(10);
      margin-bottom : rem-calc(5);
    }

    .subsect-title {
      margin    : rem-calc(5) 0;
      font-size : rem-calc(16);
    }

    .subsect-small {
      font-size   : rem-calc(12);
      font-weight : 600;
    }

    .subsect-content {
      padding   : rem-calc(5) 0;
      font-size : rem-calc(13);

      img, br, .feedflare {
        display : none;
      }
    }

    .read-more {
      padding : rem-calc(10) 0;
    }
  }

  .show-more {
    margin-top : rem-calc(10);
  }

  .subscription {
    padding-left : rem-calc(10);

    p {
      padding-bottom : rem-calc(5);
    }
  }

  .fa-newspaper-o {
    padding-top : rem-calc(8);
  }

  .hspc-form-item {
    margin-top : rem-calc(10px);
    .content {
      padding-left  : rem-calc(10);
      margin-bottom : rem-calc(10);

      a, span {
        margin-bottom : rem-calc(10);
      }
    }
  }

  .hspc-library-form-folder-root {
    padding-bottom : rem-calc(10);
    margin-bottom  : rem-calc(10);
  }

  .hspc-library-form-folder {
    @extend .hspc-form-item;
    div.content {
      margin-bottom : 0;
    }
  }

  .hspc-library-form-item {
    @extend .hspc-form-item;
    margin-top : 0;
  }

  .hspc-library-form-folder-items {
    margin-left : rem-calc(30);
  }

  //We need to specify this variable because it is the title of the tables used and the default value is white,
  //which results in an invisible title.
  .hw-table-border caption {
    color : $primary-color;
  }

  .hwDpPrintSummary {
    display : none !important;
  }

  .HwTabStopLink {
    background:#FFF;
  }

}

.unsupportedLanguage {
  padding-top:1rem;
}
