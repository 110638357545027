// HSPS buttons

button, .button {
  border-radius: .125rem;
}

 input[type="button"]:disabled, .button[disabled] {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }

.hspc-back-button{

  // @include button(medium, false, $secondary-color,
  //   scale-color($secondary-color, $lightness: -15%), auto);
  @extend .button;
  @extend .secondary;
  @extend .v-align;

  margin-bottom: rem-calc(20);
  max-width: rem-calc(57);
  max-height: rem-calc(40);

  span.arrow-back {
    color : $primary-color;
  }

  svg {
    height: rem-calc(25);
    padding-right: rem-calc(5);
    fill: $primary-color;
  }

  .button-text {
    align-self: center;
    padding-bottom: rem-calc(2.5);
    color: $primary-color;
  }
}


.hspc-table-button{
  @include button(medium, false, $secondary-color,
    scale-color($secondary-color, $lightness: -15%), auto);

  margin: 0;

  min-width  : 45px;
  min-height : 45px;

  span.arrow-next {
    font-size : 1rem;
    color     : $primary-color;
  }

  svg {
    fill   : $primary-color;
    height : 20px;
    width  : 20px;
  }
}

.workflow-buttons {
  order: 2;
  clear:left;
  border: 1px solid $app-border-color;
  align-items: center;
  flex: 0 0 auto;
}

  .section {
    margin-bottom:0;
    padding: 1rem;


    @include breakpoint(tablet down) {
      width:100%;
    }

    &.bottom {
      @include breakpoint(tablet down) {

        .button {
          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .button {
    margin-right: .625rem;

    @include breakpoint(tablet down) {
      // width: 100%;
    }

  }
