// -------------
// header select styles
@mixin color-manager($bg) {
  background-color: $bg;
  border: 1px solid rgba(isitlight($bg), .75);
  color: isitlight($bg);
}

.hspc-styled-select {
  @include grid-block;
  @include color-manager(rgba(black, .25));

  flex: 0 1 auto;
  border-radius: $global-radius;
  overflow-y:hidden;

  &:after {
    position: absolute;
    font-family: 'FontAwesome';
    font-size: rem-calc(30);
    content: "\f107";
    padding: rem-calc(4) rem-calc(8);
    right: 0;
    pointer-events: none;
    top: 0;
  }

  select {
    background: transparent;
    border: none;
    margin: 0;
    padding-right: rem-calc(35);
    color: inherit;
    line-height: normal;

    &:focus,
    &:hover {
      outline: 0;
      background: $gray-dark;
    }
    //only works in Firefox, makes option more readable
    option:disabled, option[disabled]{
      color : black;
    }
  }

  /*for IE10*/
  select::-ms-expand {
    display: none;
  }

  &.white {
    @include color-manager(rgba(white, .95));
    border: $panel-border !important;
  }

  &.yellow {
    @include color-manager($warning-color);
  }
}

// end hspc-styles-select

// -------------
// form
form {

  label {
    font-weight: $font-weight-semi-bold;

    &.required::before {
      content: '* ';
      color: $alert-color;
    }

    &.required::after {
      content: none;
    }
  }
}
//end form

// -------------
// sort-filter styles for table heads etc
.sort-filter {

  a {
    @include breakpoint(tablet down) {
      @include unselectable();
      font-size: rem-calc(18);
      margin-bottom: rem-calc(10);
    }

    &.selected,
    &.filtered {
      color: $primary-color;
    }

    padding-right:1rem;
    position:relative;
    &:after, &:before {
      font-family: FontAwesome;
      font-size: 0.75rem;
      opacity: 0.5;
      position:absolute;
      right:0;
      top: -2px;
      content: " \F106"; // font-awesome unicode: angle-up
    }
    &:before {
      top:5px;
      content: " \F107";// font-awesome unicode: angle-down
    }

    &.selected:before{
      display:none;
    }
    &.selected:after {
      font-weight: 400;
      opacity: 1;
      top:1px;
      content: "  \F078";// font-awesome unicode: chevron-down
    }
    &.selected.reverse:after {
      content: "  \F077";// font-awesome unicode: chevron-up
    }
    &.filtered:after {
      content: " \F0b0"; // fa filter
    }
  }
}

// end sort-filter

// -------------
// rounded checkbox
.hspc-rounded-checkbox {
  width: rem-calc(26);
  height: rem-calc(26);
  position: relative;
  margin: 20px auto;
  background: $primary-color;
  border-radius: rem-calc(50);

  label {
    color: transparent;
    width: rem-calc(20);
    height: rem-calc(20);
    margin: 0 !important;
    cursor: pointer;
    position: absolute;
    left: rem-calc(3);
    top: rem-calc(3);
    background: $secondary-color;
    border-radius: rem-calc(50) !important;

    &:after {
      content: '';
      width: rem-calc(20);
      height: rem-calc(20);
      position: absolute;
      top: 0;
      left: 0;
      background: lighten($primary-color, 15);
      opacity: 0;
      border-radius: rem-calc(50)
    }
    &.use-hover:hover::after {
      opacity: 0.3;
    }
  }

  input[type=checkbox] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}
// end rounded-checkbox

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $gray-dark;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $gray-dark;
}
:-ms-input-placeholder { /* IE 10+ */
  // !important is needed in IE11
  // http://stackoverflow.com/questions/22199047/placeholder-css-not-being-applied-in-ie-11
  color: $gray-dark !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: $gray-dark;
}

isc-datepicker {
  .moment-picker .moment-picker-container {
    // provides some contrast on the calendar popup, especially when its immediate background is white
    border-color: $primary-color;
    &:before {
      border-bottom-color: $primary-color;
    }

    // matches selected dates to app color
    td.selected {
      background-image: linear-gradient($primary-color, lighten($primary-color, 10));
    }
  }

  input[type='text'] {
    margin : 0;

    &[disabled] {
      opacity : 0.5;
    }

  }
}
