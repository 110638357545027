
.appointment-time{

  .selectors{
    @include breakpoint(tablet){
      align-items: center;
    }
  }

  .odd-slot{
    background: $secondary-color;
  }

  .slot{
    text-align: center;

    @include breakpoint(tablet){
      flex: 1 1 auto;
    }

    &.time-slot{
      padding: rem-calc(15);
    }

    &.day-slot{
      padding: rem-calc(15);
      @include breakpoint(tablet down){
        flex: 1 1 auto;
      }

      @include breakpoint(tablet){
        min-height: rem-calc(50);
      }
    }
  }

  .preferences-section{
    margin-top: rem-calc(20);

    .section{
      margin-top: rem-calc(10);
    }
  }
}
