$all-btn: rem-calc(40);
$all-filters-btn: rem-calc(150);

.mobile-filter-button{

  @include breakpoint(desktop down) {
    margin-#{$global-left}: rem-calc(15);
    @include bidirectional {
      margin-right : rem-calc(15);
    }
    outline               : none;
  }

  &:focus{
    outline: none;
  }

  .chevron{
    border-style: solid;
    border-width: 0.15rem 0.15rem 0 0;
    border-color: $primary-color;
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 0;
    transform: rotate(135deg);
    vertical-align: top;

    margin-#{$global-left}: rem-calc(5);
    @include bidirectional {
      margin-right : rem-calc(15); //larger to offset the chevron size
    }

    &::after {
      #{$global-left}: 0.5rem;
    }
  }
}

.filter-button-container{
  border-radius: rem-calc(3);
  border: $panel-border;
  background-color: $secondary-color;
  height: 3rem;
  overflow: hidden;
  padding: 0 $all-filters-btn 0 $all-btn;

  &.show-all {
    height: inherit;
    .isc-wellness-button {
      margin-bottom: 0;
    }
  }

  &.shift {
    padding-left  : 0.3rem;
    padding-right : 0.3rem;
  }

  .wellness-button {
    padding: rem-calc(10);
    margin-bottom: -(rem-calc(1));
    color: $button-primary;
    align-content: space-between ;
    align-items: center;

    .button-count{
      border: $panel-border;
      padding: rem-calc(2) rem-calc(5);
      background-color: white;
      margin-#{$global-left}: 5px;
      @include bidirectional{
        margin-right: 5px;
      }
    }

    .color-bar{
      margin-#{$global-right}: 5px;
      @include bidirectional{
        margin-left: 5px;
      }
    }

    &.all-btn, &.show-all-btn {
      position: absolute;
      //border-bottom: $panel-border;
      top: 0;
      height: 100%;
    }

    &.all-btn {
      border-right: $panel-border;
      width: $all-btn;
      left: 0;
    }

    &.show-all-btn {
      border-left: $panel-border;
      width: $all-filters-btn;
      right: 0;
    }

    &.active {
      background-color: white;
    }
  }

}


